import React, { useState, useEffect } from 'react';
import './calculator.scss';
import { NewTextField, Grid, Typography, Paper } from '../../common';
import { Chart } from '../../components';
import { CHART_COLOR } from '../../common/utils';
import Footer from '../../hoc/layout/footer';
const Calculator = () => {
  const [spend, setSpend] = useState(500000);
  const [cpm, setCpm] = useState(70);
  const [ctr, setCtr] = useState(0.49);
  const [duration, setDuration] = useState(30); // Add duration state

  const [reachTotal, setReachTotal] = useState(0);
  const [clickTotal, setClickTotal] = useState(0);
  const [dailyImpressions, setDailyImpressions] = useState([]);
  const [dailyClicks, setDailyClicks] = useState([]);

  const calculateLoanDetails = () => {
    let reach = (spend / cpm) * 1000;
    const ctrDecimal = ctr / 100;
    const clicks = reach * ctrDecimal;

    setReachTotal(Math.round(reach));
    setClickTotal(Math.round(clicks));

    // Generate random daily values
    const impressionsArray = [];
    const clicksArray = [];

    for (let i = 0; i < duration; i++) {
      const dailyImpressions = (Math.random() * (1.1 - 0.92) + 0.92) * (reach / duration);
      const dailyClicks = (Math.random() * (1.1 - 0.92) + 0.92) * (clicks / duration);
      impressionsArray.push(Math.round(dailyImpressions));
      clicksArray.push(Math.round(dailyClicks));
    }

    setDailyImpressions(impressionsArray);
    setDailyClicks(clicksArray);
  };

  useEffect(() => {
    calculateLoanDetails();
  }, [spend, cpm, ctr, duration]);

  const handlespendChange = (event) => {
    setSpend(Number(event.target.value));
  };

  const handleCpmChange = (event) => {
    setCpm(Number(event.target.value));
  };

  const handlectrChange = (event) => {
    setCtr(Number(event.target.value));
  };

  const handleDurationChange = (event) => {
    setDuration(Number(event.target.value));
  };

  const data = {
    labels: Array.from({ length: duration }, (_, i) => `Day ${i + 1}`), // X-axis labels for the duration
    datasets: [
      {
        label: 'Impressions',
        data: dailyImpressions,
        fill: false,
        backgroundColor: CHART_COLOR.IMPRESSION,
        borderColor: CHART_COLOR.IMPRESSION,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y',
      },
      {
        label: 'Clicks',
        data: dailyClicks,
        backgroundColor: CHART_COLOR.CLICK_COUNT,
        borderColor: CHART_COLOR.CLICK_COUNT,
        pointRadius: 0,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    scales: {
      responsive: true, // Ensures the chart resizes with the screen
      maintainAspectRatio: false, // Allows the chart to adjust its aspect ratio for responsiveness
      yAxes: [
        {
          id: 'y',
          position: 'left',
          title: {
            display: true,
            text: 'Number Of Impressions',
          },
          // ticks: {
          //   color: CHART_COLOR.IMPRESSION,
          // },
        },
        {
          id: 'y1',
          position: 'right',
          title: {
            display: true,
            text: 'Number Of Clicks',
          },
          // ticks: {
          //   color: CHART_COLOR.CLICK_COUNT,
          // },
          grid: {
            drawOnChartArea: false, // Prevent grid lines from overlapping
          },
        },
      ],
      x: {
        title: {
          display: true,
          text: 'Duration (Days)',
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  const formatCtr = (value) => {
    return `${value.toFixed(2)}%`;
  };

  return (
    <>
      <div className="header p-4">
        <Typography variant="h5" style={{ color: '#6258A8', top: 0 }}>
          Impression Calculator
        </Typography>
      </div>
      {/* <div>

      <Paper style={{color:'rgb(36, 36, 46, 0.52'}} className="pt-2 p-2 mn-h-10">
      <Typography variant="h6" style={{ color: '#6258A8',top:0 }}>
          Impression Calculator
        </Typography>
      </Paper>
      
    </div> */}
      <div className="container">
        <Grid container spacing={3} className="sub-container">
          <Grid item xs={12} md={6} className="view">
            <div className="details">
              <div>
                <div className="detail">
                  <Typography className="title-name">Spend</Typography>
                  <NewTextField
                    fullWidth
                    onChange={handlespendChange}
                    value={spend}
                    variant="outlined"
                    type="number"
                    className="text-fields"
                  />
                </div>
                <input
                  type="range"
                  value={spend}
                  onChange={handlespendChange}
                  min={0}
                  max={10000000}
                  step={50000}
                />
              </div>

              <div className="mt-4">
                <div className="detail mt-2">
                  <Typography className="title-name">CPM</Typography>
                  <NewTextField
                    fullWidth
                    onChange={handleCpmChange}
                    value={cpm}
                    variant="outlined"
                    type="number"
                    className="text-fields"
                  />
                </div>
                <input
                  id="cpm-slider"
                  type="range"
                  value={cpm}
                  onChange={handleCpmChange}
                  min={1}
                  max={200}
                  step={1}
                />
              </div>

              <div className="mt-4">
                <div className="detail mt-2">
                  <Typography className="title-name">CTR %</Typography>
                  <NewTextField
                    fullWidth
                    onChange={handlectrChange}
                    value={formatCtr(ctr)}
                    variant="outlined"
                    type="text"
                    className="text-fields"
                  />
                </div>
                <input
                  type="range"
                  value={ctr}
                  onChange={handlectrChange}
                  min={0}
                  max={2}
                  step={0.01}
                />
              </div>

              <div className="mt-4">
                <div className="detail mt-2">
                  <Typography className="title-name"> Campaign Duration (Days)</Typography>
                  <NewTextField
                    fullWidth
                    onChange={handleDurationChange}
                    value={duration}
                    variant="outlined"
                    type="number"
                    className="text-fields"
                  />
                </div>
                <input
                  type="range"
                  value={duration}
                  onChange={handleDurationChange}
                  min={1}
                  max={90}
                  step={1}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={3} className="loan-details">
              <Grid item xs={12} className="chart-details">
                <Typography className="title-name font-weight-bold">Spend</Typography>
                <Typography id="ct" className="totalvalues">
                  {spend}
                </Typography>
              </Grid>
              <Grid item xs={12} className="chart-details">
                <Typography className="title-name font-weight-bold">Impressions</Typography>
                <Typography id="cp" className="totalvalues">
                  {reachTotal}
                </Typography>
              </Grid>
              <Grid item xs={12} className="chart-details">
                <Typography className="title-name font-weight-bold">Clicks</Typography>
                <Typography id="ci" className="totalvalues">
                  {clickTotal}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} justify="center" className="mt-2">
          <Grid item xs={12} md={8} className="">
            <div className="line-chart-container">
              <Chart type="Line" chartData={data} options={options} />
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Calculator;
