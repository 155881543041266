import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { forEach, isEmpty } from 'lodash';

const Treemap = ({ data = {}, classes, legendContainerWidth = 150 }) => {
  const svgRef = useRef(null);
  const legendRef = useRef(null);
  const tooltipRef = useRef(null);

  function renderTreemap() {
    const svg = d3.select(svgRef.current);
    svg.selectAll('g').remove();

    const parentElement = svg.node().parentNode;
    const width = parentElement.getBoundingClientRect().width - legendContainerWidth;
    const height = parentElement.getBoundingClientRect().height;

    const legendContainer = d3.select(legendRef.current);
    legendContainer.selectAll('g').remove();

    svg.attr('width', width).attr('height', height);

    function showTooltip(d, i) {
      const { pageX, pageY } = d || {};
      const { data = {} } = i || {};
      const { name = '', value = '' } = data;
      let tooltipText = `<strong>${name}:</strong>`;
      if (!isEmpty(data?.tooltip) && Array.isArray(data?.tooltip)) {
        data.tooltip.forEach((row) => {
          tooltipText = `${tooltipText}<br /> ${row}`;
        });
      } else if (!isEmpty(data.tooltip)) {
        tooltipText = `<br />  ${data.tooltip}`;
      } else {
        tooltipText = `<br />  ${value}`;
      }
      const tooltip = d3.select(tooltipRef.current);
      tooltip
        .style('opacity', 1)
        .html(tooltipText)
        .style('left', pageX + 10 + 'px')
        .style('top', pageY - 28 + 'px');
    }

    function hideTooltip() {
      const tooltip = d3.select(tooltipRef.current);
      tooltip.style('opacity', 0);
    }

    function handleMouseMove(d) {
      const { pageX, pageY } = d || {};
      const tooltip = d3.select(tooltipRef.current);
      tooltip.style('left', pageX + 10 + 'px').style('top', pageY - 28 + 'px');
    }

    // create root node
    const root = d3
      .hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    // create treemap layout
    const treemapRoot = d3.treemap().size([width, height]).padding(1)(root);

    // create 'g' element nodes based on data
    const nodes = svg
      .selectAll('g')
      .data(treemapRoot.leaves())
      .join('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`);

    // create color scheme and fader
    const fader = (color) => d3.interpolateRgb(color, '#fff')(0.3);
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10.map(fader));

    // add treemap rects
    const rectElements = nodes
      .append('rect')
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .attr('fill', (d) => colorScale(d.data.category));

    // Move event handlers outside the renderTreemap function
    rectElements
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut)
      .on('mousemove', handleMouseMove);

    function handleMouseOver(d, i) {
      showTooltip(d, i);
      d3.select(this).attr('fill', (d) => d3.rgb(colorScale(d.data.category)).darker(0.6));
    }

    function handleMouseOut(d, i) {
      hideTooltip();
      d3.select(this).attr('fill', (d) => colorScale(d.data.category));
    }

    const fontSize = 12;

    // add text to rects
    nodes
      .append('text')
      .text((d) => `${d.data.name} `)
      .attr('data-width', (d) => d.x1 - d.x0)
      .attr('font-size', `${fontSize}px`)
      .attr('x', 3)
      .attr('y', fontSize)
      .call(wrapText);

    function wrapText(selection) {
      selection.each(function () {
        const node = d3.select(this);
        const rectWidth = +node.attr('data-width');
        let word;
        const words = node.text().split(' ').reverse();
        let line = [];
        let lineNumber = 0;
        const x = node.attr('x');
        const y = node.attr('y');
        let tspan = node.text('').append('tspan').attr('x', x).attr('y', y);
        while (words.length > 1) {
          word = words.pop();
          line.push(word);
          tspan.text(line.join(' '));
          const tspanLength = tspan.node().getComputedTextLength();
          if (tspanLength > rectWidth && line.length !== 1) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = addTspan(word);
          }
        }
        addTspan(words.pop());

        function addTspan(text) {
          lineNumber += 1;
          return node
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', `${lineNumber * fontSize}px`)
            .text(text);
        }
      });
    }

    // pull out hierarchy categories
    let categories = root.leaves().map((node) => node.data.category);
    categories = categories.filter((category, index, self) => self.indexOf(category) === index);

    legendContainer.attr('width', 150).attr('height', height);

    // create 'g' elements based on categories
    const legend = legendContainer.selectAll('g').data(categories).join('g');

    // create 'rects' for each category
    legend
      .append('rect')
      .attr('width', fontSize)
      .attr('height', fontSize)
      .attr('x', fontSize)
      .attr('y', (_, i) => fontSize * 2 * i)
      .attr('fill', (d) => colorScale(d));

    // add text to each category key
    legend
      .append('text')
      .attr('transform', `translate(0, ${fontSize})`)
      .attr('x', fontSize * 3)
      .attr('y', (_, i) => fontSize * 2 * i)
      .style('font-size', fontSize)
      .text((d) => d);
  }

  useEffect(() => {
    renderTreemap();
  }, [data]);

  return (
    <div className={classes ? classes : 'mn-h-400'}>
      <svg ref={svgRef} />
      <svg ref={legendRef} />
      <div ref={tooltipRef} className="treemap-tooltip" />
    </div>
  );
};

export default Treemap;
