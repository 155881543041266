import React, { Suspense, lazy, useState } from 'react';
import mobile_frame from './../../../assets/img/mobile_frame.png';
import vita from './../../../assets/img/vita.jpg';
import terabox_320_50 from './../../../assets/img/terabox_320_50.jpg';
import ukuleleTabs from './../../../assets/img/ukuleleTabs.png';
import desktop from './../../../assets/img/desktop.png';
import smartphoneicon from './../../../assets/icon/smartphoneicon.png';
import desktopicon from './../../../assets/icon/desktopicon.png';
import tableticon from './../../../assets/icon/tableticon.png';
const LazyImage = lazy(() => import('./LazyImage'));
const BANNER_320_100 = ({ imageUrl = '' }) => {
  // const [currentView, setCurrentView] = useState('mobile');

  // const handleViewChange = (view) => {
  //   setCurrentView(view);
  // };
  return (
    <>
      {/* <div className="view-buttons">
        <button
          className={`btn1 d-flex1 ${currentView === 'mobile' ? 'selected' : ''}`}
          onClick={() => handleViewChange('mobile')}
        >
          <img src={smartphoneicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'desktop' ? 'selected' : ''}`}
          onClick={() => handleViewChange('desktop')}
        >
          <img src={desktopicon} alt="" className="icons" />
        </button> */}
      {/* <button
          className={`btn1 d-flex1 ${currentView === 'tablet' ? 'selected' : ''}`}
          onClick={() => handleViewChange('tablet')}
        >
          <img src={tableticon} alt="" className="icons" />
        </button> */}
      {/* </div> */}

      {mobile_frame && (
        <div className="BANNER_320_100 mobile_app_wrapper">
          <img src={vita} alt="banner" className="app_image" />
          <img src={mobile_frame} alt="mobile" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} />
          </Suspense>
        </div>
      )}

      {/* {desktop && currentView === 'desktop' && (
        <div className=" BANNER_320_100 desktop_app_wrapper">
          <img src={ukuleleTabs} alt="banner" className="app_image1" />
          <img src={desktop} alt="desktop" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case2" />
          </Suspense>
        </div>
      )} */}
    </>
  );
};

export default BANNER_320_100;
