import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../typography';
import './loader.scss';

const Loader = (props) => {
  const { description, text, showText, isTabView = false } = props;
  const style = {};
  if (isTabView) {
    var hc = document.getElementsByClassName('header-container');
    const height = hc.length && hc[0]?.offsetHeight ? hc[0]?.offsetHeight : 0;
    style.height = `calc(100% - ${height > 85 ? height : 85}px)`;
  }
  return (
    <div className={`spinner ${isTabView && 'tab-view-loader'}`} style={style}>
      <div className="spinner-bg">
        <div {...props} role="status">
          {showText && <span className="sr-only">{text} </span>}
        </div>
      </div>
      {showText && (
        <>
          <Typography variant="paraText" classes="spinner__text mt-2">
            {text}
          </Typography>
          <span className="spinner__desc">{description}</span>
        </>
      )}
    </div>
  );
};

Loader.defaultProps = {
  className: 'spinner-border text-primary',
  text: 'Loading...',
  description: '',
  showText: false,
};

Loader.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
  showText: PropTypes.bool,
};

export default Loader;
