const defaultState = {
  loggedUser: null,
  errorLogingIn: false,
  logingIn: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_START':
      return { ...state, errorLogingIn: false, logingIn: true };
    case 'LOGIN_SET_USER':
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        errorLogingIn: false,
        loggedUser: action.payload,
        logingIn: false,
      };
    case 'LOGIN_FAILED':
      return { ...state, errorLogingIn: true, logingIn: false };
    case 'LOGIN_LOGOUT':
      return { ...state, errorLogingIn: false, loggedUser: null };
    default:
      return state;
  }
};
