import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Card from '@material-ui/core/Card';
import { NewTextField, Typography, Button, Loader } from '../../common';
import { isEmpty, uniqBy, orderBy } from 'lodash';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import getSessionData from './../../service-utils/session-util';
import './line-item-create.scss';
import { Box, CardContent, Grid, Switch, Tab, Tabs, Tooltip } from '@material-ui/core';
import { SearchInput } from '../../components';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';

const colors = {
  includeBackground: '#e8f5e9',
  includeBorder: '#4CAF50',
  excludeBackground: '#ffebee',
  excludeBorder: '#f44336',
  selectedBackground: '#f5f5f5',
  selectedBorder: '#e0e0e0',
  defaultBorder: 'transparent',
  selectedIncludeTransparent: 'rgba(76, 175, 80, 0.1)',
};

const calculateNewState = (oldState, newStateData) => {
  const newState = [...newStateData];
  let calculatedState = [];
  if (!isEmpty(oldState)) {
    oldState.forEach((os) => {
      if (!isEmpty(newState)) {
        newState.forEach((ns) => {
          if (os.id === ns.id) {
            calculatedState = [
              ...calculatedState,
              {
                ...os,
                children: orderBy(uniqBy([...os.children, ...ns.children], 'id'), [
                  'orderId',
                  'label',
                ]),
              },
            ];
          } else {
            calculatedState = orderBy(uniqBy([...calculatedState, os, ns], 'id'), [
              'orderId',
              'label',
            ]);
          }
        });
      }
    });
  } else {
    calculatedState = newState;
  }
  return orderBy(calculatedState, ['orderId', 'label']);
};

const handleDataTransfer = (
  mainList,
  { allSelectedIds = [], selectedObj = {} },
  setMainList,
  setTargetList,
) => {
  let tempMainList = [];
  let tempTargetList = [];

  if (!isEmpty(mainList)) {
    mainList.forEach((item) => {
      let mainListItem = {};
      let targetListItem = {};
      if (allSelectedIds.includes(item.id)) {
        targetListItem = { ...item, children: [] };
      }
      if (
        !allSelectedIds.includes(item.id) ||
        (allSelectedIds.includes(item.id) &&
          item.children?.length > selectedObj[item.id]?.children?.length)
      ) {
        mainListItem = { ...item, children: [] };
      }

      if (!isEmpty(item.children)) {
        item.children.forEach((itemChild) => {
          if (allSelectedIds.includes(itemChild.id)) {
            if (isEmpty(targetListItem.children)) {
              targetListItem = {
                ...targetListItem,
                children: [itemChild],
              };
            } else {
              targetListItem = {
                ...targetListItem,
                children: [...targetListItem.children, itemChild],
              };
            }
          } else {
            if (isEmpty(mainListItem.children)) {
              mainListItem = {
                ...mainListItem,
                children: [itemChild],
              };
            } else {
              mainListItem = {
                ...mainListItem,
                children: [...mainListItem.children, itemChild],
              };
            }
          }
        });
      }

      tempMainList = isEmpty(mainListItem) ? tempMainList : [...tempMainList, mainListItem];
      tempTargetList = isEmpty(targetListItem)
        ? tempTargetList
        : [...tempTargetList, targetListItem];
    });
  }

  setTargetList((oldState) => {
    return calculateNewState(oldState, tempTargetList);
  });
  setMainList(orderBy(tempMainList, ['orderId', 'label']));

  return orderBy(tempMainList, ['orderId', 'label']);
};

const IncludeExclude = ({
  masterTargetingId,
  masterList,
  isLoading,
  setPayload,
  savedTargetings = {},
  masterFetchData,
}) => {
  const [includeList, setIncludeList] = useState([]);
  const [excludeList, setExcludeList] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const indiaId = masterData.find((item) => item.label === 'INDIA')?.id; // Find India's ID
  const [selectedItem, setSelectedItem] = useState(indiaId || null);
  const [selectAllChild, setSelectAllChild] = useState({});
  const [allSelectedItems, setAllSelectedItems] = useState(true);
  const ChildItem = ({ child, includeList, excludeList, handleCheck, selectedItem }) => {
    const isChildIncluded = includeList.includes(child?.id);
    const isChildExcluded = excludeList.includes(child?.id);

    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        mb={2}
        className="customContainer parentChildbg"
      >
        <Grid item xs={4}>
          <Box pl={2}>
            <Typography style={{ fontSize: '1rem', lineHeight: '1.5' }}>{child.label}</Typography>
          </Box>
        </Grid>
        {['include', 'exclude'].map((type) => (
          <Grid item xs={4} key={type}>
            <Box display="flex" justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={type === 'include' ? isChildIncluded : isChildExcluded}
                    onChange={() => handleCheck(child.id, type, selectedItem)}
                  />
                }
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const MenuItem = ({
    item,
    includeList,
    excludeList,
    handleCheck,
    handleItemClick,
    selectedItem,
  }) => {
    // Determine inclusion and exclusion status
    const isIncluded =
      (includeList.length === 0 && !excludeList.includes(item.id)) || includeList.includes(item.id);
    const isExcluded = excludeList.includes(item.id);

    const counts = item.children ? getitemSelectionCounts(item.id) : null;

    // Apply highlight style based on initial selection and inclusion/exclusion state
    const highlightStyle = {
      backgroundColor: isIncluded
        ? colors.selectedIncludeTransparent // Background for included items
        : isExcluded
        ? colors.excludeBackground // Background for excluded items
        : selectedItem === item.id
        ? '#e0f7fa'
        : 'transparent', // Default transparent if neither is included or excluded
      border: isIncluded
        ? `1px solid ${colors.includeBorder}` // Border for included items
        : isExcluded
        ? `1px solid ${colors.excludeBorder}` // Border for excluded items
        : '1px solid transparent', // Default transparent border
    };

    return (
      <Grid container spacing={2} alignItems="center" mb={2} className="customContainer">
        <Grid item xs={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            className="list-item cursor-pointer"
            style={highlightStyle}
            onClick={() => item.children && handleItemClick(item.id)}
          >
            <Typography>{item.label}</Typography>
            {counts && (counts.include > 0 || counts.exclude > 0) && (
              <Box display="flex" gap={1}>
                {counts.include > 0 && (
                  <Typography className="count-element bg-include-color">
                    +{counts.include}
                  </Typography>
                )}
                {counts.exclude > 0 && (
                  <Typography className="count-element bg-exclude-color">
                    -{counts.exclude}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        {['include', 'exclude'].map((type) => (
          <Grid item xs={4} key={type}>
            <Box display="flex" justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={type === 'include' ? isIncluded : isExcluded}
                    onChange={() => handleCheck(item.id, type, item.children ? item : item.id)}
                  />
                }
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const InfoIconData = [
    {
      id: 17,
      name: 'Placement',
      info: 'All Placement, can target every placement in the platform inventory. Including the clusters you selected below except the excluded ones',
    },
    {
      id: 20,
      name: 'PinCode',
      info: 'All PinCode, can target any PinCode from the selected Country and states. Including the segment you selected below except the excluded ones.',
    },
    {
      id: 21,
      name: 'IP',
      info: 'All IP addresses, can target any IP address from the selected Country and states. Including the segment you selected below except the excluded ones.',
    },

    {
      id: 22,
      name: 'device',
      info: 'All devices, can target any device. Including the segment you selected below except the excluded ones',
    },
  ];

  const getFinalPayloadIds = (list, allChildMap, isAllChecked) => {
    if (isAllChecked) {
      return [];
    }

    const finalIds = new Set();
    const processedIds = new Set();

    Object.entries(allChildMap).forEach(([itemId, child]) => {
      if (!child || child.length === 0) return;
      if (child.length === 1 && child[0] === parseInt(itemId)) {
        if (list.includes(parseInt(itemId))) {
          finalIds.add(parseInt(itemId));
        }
        return;
      }

      // Extract child IDs, handling both number and object types
      const childIds = child.map((c) => (typeof c === 'number' ? c : c.id));

      // Check if all child IDs are in the list
      const allChildIncluded = childIds?.every((id) => list?.includes(id));

      if (allChildIncluded) {
        finalIds.add(parseInt(itemId));
        childIds.forEach((id) => processedIds.add(id));
      }
    });

    // Add remaining unprocessed IDs from the original list
    list?.forEach((id) => {
      if (!processedIds.has(id)) {
        finalIds.add(id);
      }
    });

    return Array.from(finalIds);
  };

  const autoSave = useCallback(
    (newIncludeList, newExcludeList) => {
      console.log('Debug AutoSave Input:', {
        newIncludeList,
        newExcludeList,
        masterData,
      });
      const itemChildMap = {};
      masterData?.forEach((item) => {
        if (item.children) {
          itemChildMap[item.id] = item.children;
        } else {
          itemChildMap[item.id] = [{ id: item.id }];
        }
      });

      // Generate the final include and exclude IDs
      const finalIncludeIds = getFinalPayloadIds(newIncludeList, itemChildMap);
      const finalExcludeIds = getFinalPayloadIds(newExcludeList, itemChildMap);

      const payloadData = {
        masterTargetingId,
        include: finalIncludeIds,
        exclude: finalExcludeIds,
        selectedItem,
      };

      // Check if the payload data has actually changed
      setPayload((oldPayload) => {
        const currentData = oldPayload?.[masterTargetingId];

        // Compare only the relevant fields
        const isDataUnchanged =
          currentData &&
          JSON.stringify(currentData.include) === JSON.stringify(payloadData.include) &&
          JSON.stringify(currentData.exclude) === JSON.stringify(payloadData.exclude) &&
          currentData.selectedItem === payloadData.selectedItem;

        if (isDataUnchanged) {
          return oldPayload; // Return the old payload if no changes
        }

        // Return the new payload if any field has changed
        return {
          ...oldPayload,
          [masterTargetingId]: payloadData,
        };
      });
    },
    [masterTargetingId, selectedItem, setPayload, masterData],
  );

  const memoizedAutoSave = useCallback(autoSave, []);

  useEffect(() => {
    const orderedMasterData = orderBy(masterList, ['orderId', 'label']);
    setMasterData(orderedMasterData);
  }, [masterList]);

  useEffect(() => {
    if (masterData.length > 0 && !savedTargetings.include && !savedTargetings.exclude) {
      let defaultIncludeList = [];
      const defaultSelectAll = {};

      masterData.forEach((item) => {
        if (item.children) {
          const childIds = item.children.map((child) => child.id);
          defaultIncludeList.push(...childIds);
          defaultSelectAll[item.id] = { include: true, exclude: false };
        } else {
          defaultIncludeList.push(item.id);
        }
      });

      setIncludeList(defaultIncludeList);
      setSelectAllChild(defaultSelectAll);
      memoizedAutoSave(defaultIncludeList, []);
    }
  }, [masterData, savedTargetings, memoizedAutoSave]);
  useEffect(() => {
    const orderedMasterData = orderBy(masterList, ['orderId', 'label']);
    setMasterData(orderedMasterData);

    // If there are no saved targetings, select all items by default
    if (!savedTargetings.include && !savedTargetings.exclude) {
      let defaultIncludeList = [];
      const defaultSelectAll = {};

      orderedMasterData.forEach((item) => {
        if (item.children) {
          // For items with children, include all child IDs
          const childIds = item.children.map((child) => child.id);
          defaultIncludeList = [...defaultIncludeList, ...childIds];
          defaultSelectAll[item.id] = { include: true, exclude: false };
        } else {
          // For items without children, include the item ID
          defaultIncludeList.push(item.id);
        }
      });

      setIncludeList(defaultIncludeList);
      setSelectAllChild(defaultSelectAll);
      autoSave(defaultIncludeList, []);
    } else {
      // Handle saved targetings as before
      if (savedTargetings.include) {
        let expandedIncludeList = [];
        savedTargetings.include.forEach((id) => {
          const item = orderedMasterData.find((c) => c.id === id);
          if (item?.children) {
            expandedIncludeList = [
              ...expandedIncludeList,
              ...item.children.map((child) => child.id),
            ];
          } else {
            expandedIncludeList.push(id);
          }
        });
        setIncludeList(expandedIncludeList);
        const includeSelectAll = {};
        orderedMasterData.forEach((item) => {
          if (item.children) {
            const itemChild = item.children.map((child) => child.id);
            const allIncluded = itemChild.every((childId) => expandedIncludeList.includes(childId));
            if (allIncluded) {
              includeSelectAll[item.id] = { include: true, exclude: false };
            }
          }
        });
        setSelectAllChild(includeSelectAll);
      }

      if (savedTargetings.exclude) {
        let expandedExcludeList = [];
        savedTargetings.exclude.forEach((id) => {
          const item = orderedMasterData.find((c) => c.id === id);
          if (item?.children) {
            expandedExcludeList = [
              ...expandedExcludeList,
              ...item.children.map((child) => child.id),
            ];
          } else {
            expandedExcludeList.push(id);
          }
        });
        setExcludeList(expandedExcludeList);
        const excludeSelectAll = {};
        orderedMasterData.forEach((item) => {
          if (item.children) {
            const itemChild = item.children.map((child) => child.id);
            const allExcluded = itemChild.every((childId) => expandedExcludeList.includes(childId));
            if (allExcluded) {
              excludeSelectAll[item.id] = { include: false, exclude: true };
            }
          }
        });
        setSelectAllChild((prev) => ({ ...prev, ...excludeSelectAll }));
      }
    }
  }, [masterList, savedTargetings]);

  useEffect(() => {
    if (indiaId) {
      handleItemClick(indiaId);
    }
  }, [indiaId]);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  useEffect(() => {
    if (savedTargetings.include || savedTargetings.exclude) {
      const expandedIncludeList = [];
      const expandedExcludeList = [];
      const selectAllStates = {};

      savedTargetings.include?.forEach((id) => {
        const item = masterData.find((c) => c.id === id);
        if (item?.children) {
          expandedIncludeList.push(...item.children.map((child) => child.id));
        } else {
          expandedIncludeList.push(id);
        }
      });

      savedTargetings.exclude?.forEach((id) => {
        const item = masterData.find((c) => c.id === id);
        if (item?.children) {
          expandedExcludeList.push(...item.children.map((child) => child.id));
        } else {
          expandedExcludeList.push(id);
        }
      });

      masterData.forEach((item) => {
        if (item.children) {
          const childIds = item.children.map((child) => child.id);
          const allIncluded = childIds.every((childId) => expandedIncludeList.includes(childId));
          const allExcluded = childIds.every((childId) => expandedExcludeList.includes(childId));
          if (allIncluded || allExcluded) {
            selectAllStates[item.id] = { include: allIncluded, exclude: allExcluded };
          }
        }
      });

      setIncludeList(expandedIncludeList);
      setExcludeList(expandedExcludeList);
      setSelectAllChild(selectAllStates);
    }
  }, [masterData, savedTargetings]);

  const handleCheck = (childId, actionType, itemOrId) => {
    const itemId = typeof itemOrId === 'number' ? itemOrId : itemOrId.id;
    const item = masterData.find((c) => c.id === itemId);
    const itemChild = item?.children?.map((child) => child.id);

    if (actionType === 'include') {
      setIncludeList((prevIncludeList) => {
        const updatedList = prevIncludeList.includes(childId)
          ? prevIncludeList.filter((id) => id !== childId)
          : [...prevIncludeList, childId];

        if (itemChild) {
          const allIncluded = itemChild.every((id) =>
            id === childId ? updatedList.includes(childId) : updatedList.includes(id),
          );
          setSelectAllChild((prev) => ({
            ...prev,
            [itemId]: { include: allIncluded, exclude: false },
          }));
        }

        const newExcludeList = excludeList.filter((id) => id !== childId);
        setExcludeList(newExcludeList);
        memoizedAutoSave(updatedList, newExcludeList);
        return updatedList;
      });
    } else if (actionType === 'exclude') {
      setExcludeList((prevExcludeList) => {
        const updatedList = prevExcludeList.includes(childId)
          ? prevExcludeList.filter((id) => id !== childId)
          : [...prevExcludeList, childId];

        if (itemChild) {
          const allExcluded = itemChild.every((id) =>
            id === childId ? updatedList.includes(childId) : updatedList.includes(id),
          );
          setSelectAllChild((prev) => ({
            ...prev,
            [itemId]: { exclude: allExcluded, include: false },
          }));
        }

        const newIncludeList = includeList.filter((id) => id !== childId);
        setIncludeList(newIncludeList);
        memoizedAutoSave(newIncludeList, updatedList);
        return updatedList;
      });
    }
  };
  useEffect(() => {
    if (!Array.isArray(masterData) || masterData.length === 0) return;

    const india = masterData.find((item) => item.id === 1);

    const isIncludeListEmpty =
      !includeList || (Array.isArray(includeList) && includeList.length === 0);
    const isExcludeListEmpty =
      !excludeList || (Array.isArray(excludeList) && excludeList.length === 0);

    if (isIncludeListEmpty && isExcludeListEmpty) {
      if (india && Array.isArray(india.children)) {
        const defaultIncludeList = india.children.map((child) => child.id);
        if (JSON.stringify(defaultIncludeList) !== JSON.stringify(includeList)) {
          setIncludeList(defaultIncludeList);

          setSelectAllChild((prev) => ({
            ...prev,
            [india.id]: { include: true, exclude: false },
          }));

          autoSave(defaultIncludeList, []);
        }
      }
    }
  }, [masterData, includeList, excludeList]);

  const handleSelectAll = (actionType, allChild, itemId) => {
    // Case 1: Data without children (flat list)
    if (!masterFetchData?.child) {
      const allIds = masterData.map((item) => item.id);

      if (actionType === 'include') {
        const allIncluded = allIds.every((id) => includeList.includes(id));

        if (allIncluded) {
          // Uncheck all
          setIncludeList([]);
          setSelectAllChild({});
          autoSave([], excludeList);
        } else {
          // Check all
          const newIncludeList = [...allIds];
          setIncludeList(newIncludeList);
          setExcludeList([]);
          const newSelectAllChild = {};
          allIds.forEach((id) => {
            newSelectAllChild[id] = { include: true, exclude: false };
          });
          setSelectAllChild(newSelectAllChild);
          autoSave(newIncludeList, []);
        }
      } else if (actionType === 'exclude') {
        const allExcluded = allIds.every((id) => excludeList.includes(id));

        if (allExcluded) {
          // Uncheck all
          setExcludeList([]);
          setSelectAllChild({});
          autoSave(includeList, []);
        } else {
          // Check all
          const newExcludeList = [...allIds];
          setExcludeList(newExcludeList);
          setIncludeList([]);
          const newSelectAllChild = {};
          allIds.forEach((id) => {
            newSelectAllChild[id] = { include: false, exclude: true };
          });
          setSelectAllChild(newSelectAllChild);
          autoSave([], newExcludeList);
        }
      }
      return;
    }

    // Case 2: No item selected or item has no children
    if (!selectedItem || !allChild || allChild.length === 0) {
      if (actionType === 'include') {
        const isCurrentlySelected = includeList.includes(selectedItem);
        if (isCurrentlySelected) {
          // Uncheck
          const newIncludeList = includeList.filter((id) => id !== selectedItem);
          setIncludeList(newIncludeList);
          setSelectAllChild((prev) => ({
            ...prev,
            [selectedItem]: { include: false, exclude: false },
          }));
          autoSave(newIncludeList, excludeList);
        } else {
          // Check
          const newIncludeList = [...includeList, selectedItem];
          const newExcludeList = excludeList.filter((id) => id !== selectedItem);
          setIncludeList(newIncludeList);
          setExcludeList(newExcludeList);
          setSelectAllChild((prev) => ({
            ...prev,
            [selectedItem]: { include: true, exclude: false },
          }));
          autoSave(newIncludeList, newExcludeList);
        }
      } else if (actionType === 'exclude') {
        const isCurrentlyExcluded = excludeList.includes(selectedItem);
        if (isCurrentlyExcluded) {
          // Uncheck
          const newExcludeList = excludeList.filter((id) => id !== selectedItem);
          setExcludeList(newExcludeList);
          setSelectAllChild((prev) => ({
            ...prev,
            [selectedItem]: { include: false, exclude: false },
          }));
          autoSave(includeList, newExcludeList);
        } else {
          // Check
          const newExcludeList = [...excludeList, selectedItem];
          const newIncludeList = includeList.filter((id) => id !== selectedItem);
          setExcludeList(newExcludeList);
          setIncludeList(newIncludeList);
          setSelectAllChild((prev) => ({
            ...prev,
            [selectedItem]: { include: false, exclude: true },
          }));
          autoSave(newIncludeList, newExcludeList);
        }
      }
      return;
    }

    // Case 3: Item with children selected
    const item = masterData.find((c) => c.id === selectedItem);
    if (!item?.children) return;

    const itemChildren = item.children.map((child) => child.id);

    if (actionType === 'include') {
      const allChildrenIncluded = itemChildren.every((id) => includeList.includes(id));

      if (allChildrenIncluded) {
        // Uncheck all children
        const newIncludeList = includeList.filter((id) => !itemChildren.includes(id));
        setIncludeList(newIncludeList);
        setSelectAllChild((prev) => ({
          ...prev,
          [selectedItem]: { include: false, exclude: false },
        }));
        autoSave(newIncludeList, excludeList);
      } else {
        // Check all children
        const newIncludeList = [
          ...includeList.filter((id) => !itemChildren.includes(id)),
          ...itemChildren,
        ];
        const newExcludeList = excludeList.filter((id) => !itemChildren.includes(id));
        setIncludeList(newIncludeList);
        setExcludeList(newExcludeList);
        setSelectAllChild((prev) => ({
          ...prev,
          [selectedItem]: { include: true, exclude: false },
        }));
        autoSave(newIncludeList, newExcludeList);
      }
    } else if (actionType === 'exclude') {
      const allChildrenExcluded = itemChildren.every((id) => excludeList.includes(id));

      if (allChildrenExcluded) {
        // Uncheck all children
        const newExcludeList = excludeList.filter((id) => !itemChildren.includes(id));
        setExcludeList(newExcludeList);
        setSelectAllChild((prev) => ({
          ...prev,
          [selectedItem]: { include: false, exclude: false },
        }));
        autoSave(includeList, newExcludeList);
      } else {
        // Check all children
        const newExcludeList = [
          ...excludeList.filter((id) => !itemChildren.includes(id)),
          ...itemChildren,
        ];
        const newIncludeList = includeList.filter((id) => !itemChildren.includes(id));
        setExcludeList(newExcludeList);
        setIncludeList(newIncludeList);
        setSelectAllChild((prev) => ({
          ...prev,
          [selectedItem]: { include: false, exclude: true },
        }));
        autoSave(newIncludeList, newExcludeList);
      }
    }
  };

  const getItemHighlightStyle = (item) => {
    if (item.children && item.children.length > 0) {
      const counts = getitemSelectionCounts(item.id);
      if (counts.include > 0)
        return {
          backgroundColor: colors.selectedIncludeTransparent,
          border: `1px solid ${colors.includeBorder}`,
        };
      if (counts.exclude > 0)
        return {
          backgroundColor: colors.excludeBackground,
          border: `1px solid ${colors.excludeBorder}`,
        };
    } else {
      if (includeList.includes(item.id))
        return {
          backgroundColor: colors.selectedIncludeTransparent,
          border: `1px solid ${colors.includeBorder}`,
        };
      if (excludeList.includes(item.id))
        return {
          backgroundColor: colors.excludeBackground,
          border: `1px solid ${colors.excludeBorder}`,
        };
    }
    return {
      backgroundColor: selectedItem === item.id ? colors.selectedBackground : colors.defaultBorder,
      border:
        selectedItem === item.id
          ? `1px solid ${colors.selectedBorder}`
          : `1px solid ${colors.defaultBorder}`,
    };
  };

  const getitemSelectionCounts = (itemId) => {
    const item = masterData.find((c) => c.id === itemId);
    if (!item || !item.children) return { include: 0, exclude: 0 };

    const childIds = item.children.map((child) => child.id);
    const includeCount = childIds.filter((id) => includeList.includes(id)).length;
    const excludeCount = childIds.filter((id) => excludeList.includes(id)).length;

    return { include: includeCount, exclude: excludeCount };
  };

  const handleSelectAllSelectedItem = (event) => {
    const isChecked = event.target.checked;

    const finalPayload = isChecked
      ? []
      : masterData.map((item) => item.id).filter((id) => !includeList.includes(id));
    updateSelectedItems(finalPayload);
  };

  const updateSelectedItems = (payload) => {
    const safePayload = Array.isArray(payload) ? payload : [];
    setIncludeList(safePayload);
    memoizedAutoSave(safePayload);
    console.log('Updated includeList:', safePayload);
  };

  useEffect(() => {
    const allItemsSelected = masterData.length > 0 && includeList.length === 0;

    setAllSelectedItems(allItemsSelected);
  }, [includeList, masterData]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                {masterFetchData?.label}
              </Typography>
              {masterFetchData?.child && (
                <Box>
                  {masterData?.map((item) => {
                    const counts = item.children ? getitemSelectionCounts(item.id) : null;
                    const highlightStyle = getItemHighlightStyle(item);
                    const isSelected = selectedItem === item.id;

                    return (
                      <Box
                        key={item.id}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}
                        className="list-item cursor-pointer"
                        style={{ ...highlightStyle }}
                        onClick={() => handleItemClick(item.id)}
                      >
                        <Typography style={{ fontWeight: isSelected ? 'bold' : 'normal' }}>
                          {item.label}
                        </Typography>
                        {counts && (counts.include > 0 || counts.exclude > 0) && (
                          <Box display="flex" gap={1}>
                            {counts.include > 0 && (
                              <Typography className="count-element bg-include-color">
                                +{counts.include}
                              </Typography>
                            )}
                            {counts.exclude > 0 && (
                              <Typography className="count-element bg-exclude-color">
                                -{counts.exclude}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {masterFetchData?.child &&
                    masterData.find((c) => c.id === selectedItem)?.children
                      ? 'States'
                      : ''}
                  </Typography>
                </Grid>
                {['include', 'exclude'].map((type) => (
                  <Grid item xs={4} key={type} className="mb-4">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography className={`font-weight-bold ${type}-color mb-1`}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (type === 'include' &&
                                includeList.length === 0 &&
                                excludeList.length === 0) ||
                              (masterFetchData?.child
                                ? selectedItem
                                  ? type === 'include'
                                    ? includeList.includes(selectedItem) ||
                                      selectAllChild[selectedItem]?.include
                                    : excludeList.includes(selectedItem) ||
                                      selectAllChild[selectedItem]?.exclude
                                  : false
                                : type === 'include'
                                ? masterData.every((item) => includeList.includes(item.id))
                                : masterData.every((item) => excludeList.includes(item.id)))
                            }
                            onChange={() =>
                              handleSelectAll(
                                type,
                                masterData.find((c) => c.id === selectedItem)?.children,
                                selectedItem,
                              )
                            }
                          />
                        }
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box>
                {masterData?.length === 0 ? (
                  <Grid className="mb-4">
                    <Box display="flex" className="ml-2">
                      <Typography
                        className="list-item cursor-pointer"
                        style={{
                          backgroundColor: 'rgba(76, 175, 80, 0.1)', // Light green background
                          border: '1px solid #4CAF50', // Green border
                          fontWeight: 'normal',
                          borderRadius: '4px', // Optional: Rounded corners
                        }}
                      >
                        {masterFetchData?.includeAllDesc}
                      </Typography>
                      {masterFetchData?.id && (
                        <Tooltip
                          title={
                            <Typography className="TooltipFontSize">
                              {InfoIconData.find((info) => info.id === masterFetchData.id)?.info ||
                                'No information available'}
                            </Typography>
                          }
                          arrow
                          placement="bottom"
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon className="infoIcon" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <Box display="flex" justifyContent="center">
                      {' '}
                      <FormControlLabel
                        className=""
                        style={{ marginTop: '-40px' }}
                        control={
                          <Checkbox
                            checked={true}
                            // onChange={handleSelectAllSelectedItem}
                          />
                        }
                        // label={<Typography className="font-weight-bold">All</Typography>}
                      />
                    </Box>
                  </Grid>
                ) : masterFetchData?.child ? (
                  masterData
                    .find((item) => item?.id === selectedItem)
                    ?.children?.map((child) => (
                      <ChildItem
                        key={child.id}
                        child={child}
                        includeList={includeList}
                        excludeList={excludeList}
                        handleCheck={handleCheck}
                        selectedItem={selectedItem}
                      />
                    ))
                ) : (
                  <>
                    {!masterFetchData?.child && (
                      <>
                        <Grid className="mb-4">
                          <Box display="flex" className="ml-2" alignItems="center">
                            <Typography
                              className="list-item cursor-pointer"
                              style={{
                                backgroundColor: 'rgba(76, 175, 80, 0.1)', // Light green background
                                border: '1px solid #4CAF50', // Green border
                                fontWeight: 'normal',
                                borderRadius: '4px', // Optional: Rounded corners
                              }}
                            >
                              {masterFetchData?.includeAllDesc}
                            </Typography>
                            {masterFetchData?.id && (
                              <Tooltip
                                title={
                                  <Typography className="TooltipFontSize">
                                    {InfoIconData.find((info) => info.id === masterFetchData.id)
                                      ?.info || 'No information available'}
                                  </Typography>
                                }
                                arrow
                                placement="bottom"
                              >
                                <IconButton aria-label="info">
                                  <InfoOutlinedIcon className="infoIcon" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                          <Box display="flex" justifyContent="center">
                            <FormControlLabel
                              className=""
                              style={{ marginTop: '-40px' }}
                              control={
                                <Checkbox
                                  checked={allSelectedItems}
                                  onChange={handleSelectAllSelectedItem}
                                />
                              }
                            />
                          </Box>
                        </Grid>
                      </>
                    )}
                    {masterData.map((item) => (
                      <MenuItem
                        key={item.id}
                        item={item}
                        includeList={includeList}
                        excludeList={excludeList}
                        handleCheck={handleCheck}
                        handleItemClick={handleItemClick}
                        selectedItem={selectedItem}
                      />
                    ))}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

const SingleSelect = ({
  masterTargetingId,
  masterList,
  savedTargetings = {},
  setPayload,
  isLoading,
}) => {
  const [checked, setChecked] = useState();

  const data = useMemo(() => {
    const savedIds = savedTargetings?.include || [];
    if (!isEmpty(savedIds)) {
      setChecked(savedIds[0]);
    }
    return masterList?.map((item) => ({
      ...item,
      name: item.label,
    }));
  }, [masterList, savedTargetings]);

  const handleChange = (event) => {
    setChecked(event.target.value);
  };

  useEffect(() => {
    setPayload((oldchild) => ({
      ...oldchild,
      [masterTargetingId]: { masterTargetingId, exclude: [], include: [parseInt(checked)] },
    }));
  }, [checked]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <FormControl component="fieldset" className="singleselect-targeting m-2">
          <RadioGroup name="singleSelect" value={checked?.toString()} onChange={handleChange}>
            {data.map((item) => (
              <FormControlLabel
                key={item.id} // Added key for unique identification
                value={item.id?.toString()}
                control={<Radio color="primary" />} // Changed color to primary
                label={<span style={{ fontWeight: 'bold' }}>{item.name}</span>} // Bold label
                className="radio-btns"
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};
const MultiSelect = ({
  masterTargetingId,
  masterList,
  savedTargetings = {},
  setPayload,
  isLoading,
  checked,
  setChecked, // Passed down from TargetingOption
}) => {
  // const [checked, setChecked] = useState(() => {
  //   const savedIds = savedTargetings?.include || [];
  //   const initialChecked = {};

  //   if (savedIds.length === 0) {
  //     masterList?.forEach((item) => {
  //       initialChecked[item.id] = true;
  //     });
  //   } else {
  //     savedIds.forEach((id) => {
  //       initialChecked[id] = true;
  //     });
  //   }
  //   return initialChecked;
  // });

  const [selectAll, setSelectAll] = useState(false); // State for Select All toggle

  const data = useMemo(() => {
    return masterList?.map((item) => ({
      ...item,
      name: item.label,
      isChecked: !!checked[item.id],
    }));
  }, [masterList, checked]);

  const handleChange = (event, id) => {
    const isChecked = event.target.checked;

    setChecked((prev) => ({
      ...prev,
      [id]: isChecked,
    }));

    const newChecked = {
      ...checked,
      [id]: isChecked,
    };

    const selectedIds = Object.keys(newChecked)
      .filter((key) => newChecked[key])
      .map((key) => parseInt(key));

    const allSelected = selectedIds.length === masterList.length;

    const includeIds = allSelected ? [] : selectedIds;

    setPayload((oldPayload) => ({
      ...oldPayload,
      [masterTargetingId]: {
        masterTargetingId,
        exclude: [],
        include: includeIds,
      },
    }));
  };
  // const handleSelectAll = (event) => {
  //   const isChecked = event.target.checked; // Get the state of the toggle

  //   // Update the "Select All" state first
  //   setSelectAll(isChecked);

  //   // Set checked state for each item based on "Select All"
  //   const newChecked = {};
  //   masterList?.forEach((item) => {
  //     newChecked[item.id] = isChecked;
  //   });

  //   // Update the checked state
  //   setChecked(newChecked);
  // };

  useEffect(() => {
    if (!masterList || masterList.length === 0) return;

    const selectedIds = Object.keys(checked)
      .filter((key) => checked[key]) // Get keys of items that are checked
      .map((key) => parseInt(key, 10)); // Convert keys to integers (IDs)

    // Determine the selected IDs or empty list
    const allSelected = selectedIds.length === masterList.length;
    const includeIds = allSelected ? [] : selectedIds; // If all are selected, pass an empty list

    // Update the payload only when the checked state changes
    setPayload((oldPayload) => ({
      ...oldPayload,
      [masterTargetingId]: {
        masterTargetingId,
        exclude: [],
        include: includeIds,
      },
    }));
  }, [checked, masterTargetingId, setPayload]);

  useEffect(() => {
    if (!masterList) return;
    const initialChecked = {};
    masterList.forEach((item) => {
      initialChecked[item.id] = checked[item.id] || false;
    });
    setChecked(initialChecked);
  }, [masterList]);

  useEffect(() => {
    const savedIds = savedTargetings?.include || [];
    const initialChecked = {};
    if (savedIds.length === 0) {
      masterList?.forEach((item) => {
        initialChecked[item.id] = true;
      });
    } else {
      savedIds.forEach((id) => {
        initialChecked[id] = true;
      });
    }
    setChecked(initialChecked);
    setSelectAll(Object.keys(initialChecked).length === masterList.length);
  }, [masterList]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <FormControl required>
          <FormGroup
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '30px',
            }}
          >
            {data?.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Switch checked={!!checked[item.id]} onChange={(e) => handleChange(e, item.id)} />
                }
                label={
                  <Typography
                    style={{
                      backgroundColor: checked[item.id]
                        ? colors.selectedIncludeTransparent
                        : colors.defaultBorder,
                      border: checked[item.id]
                        ? `1px solid ${colors.includeBorder}`
                        : `1px solid ${colors.defaultBorder}`,
                      borderRadius: '4px',
                      padding: '5px',
                      display: 'inline-block',
                    }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};

export const TargetingOption = ({
  data,
  savedTargetings = {},
  isSavedListLoading = false,
  setPayload,
}) => {
  const { id, type = '', targeting = '', endpoint } = data;
  let { agencyId = '' } = getSessionData();
  const queryStringParams = {
    agencyId,
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [checked, setChecked] = useState({}); // State to track which items are checked

  const { data: masterList = [], isLoading: isMasterLoading } = useQuery(
    ['TARGETING_LIST', targeting],
    async () => {
      const response = await Services.getTargetingList(
        endpoint,
        queryString.stringify(queryStringParams),
      );
      return response.data?.masterList;
    },
  );

  const filteredMasterList = useMemo(() => {
    return masterList?.filter((item) => {
      const mainLabelMatch = item.label.toLowerCase().includes(searchQuery.toLowerCase());
      const childrenMatch = item.children?.some((child) =>
        child.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      return mainLabelMatch || childrenMatch;
    });
  }, [masterList, searchQuery]);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const newChecked = {};

    if (type === 'MULTI_SELECT' || type === 'checkbox') {
      masterList.forEach((item) => {
        newChecked[item.id] = isChecked;
      });

      setChecked(newChecked);

      const includeIds = isChecked ? masterList.map((item) => item.id) : [];
      setPayload((oldPayload) => ({
        ...oldPayload,
        [id]: {
          masterTargetingId: id,
          include: includeIds,
          exclude: [],
        },
      }));
    }
  };

  return (
    <>
      <div className="mb-4 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div>
          {(type === 'MULTI_SELECT' || type === 'checkbox' || type === 'SINGLE_SELECT') && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    type === 'SINGLE_SELECT'
                      ? checked[masterList[0]?.id]
                      : masterList.length > 0 &&
                        Object.values(checked).filter(Boolean).length === masterList.length
                  }
                  indeterminate={
                    Object.values(checked).some((val) => val) &&
                    Object.values(checked).some((val) => !val)
                  }
                  onChange={handleSelectAllChange}
                />
              }
              label="Select All"
            />
          )}
        </div>
      </div>

      {type === 'MULTI_SELECT' && (
        <>
          <div className="d-flex mb-3">
            <Typography variant="subtitle1" fontWeight="bold">
              {data?.label}
            </Typography>
          </div>
          <MultiSelect
            masterTargetingId={id}
            masterList={filteredMasterList}
            savedTargetings={savedTargetings[id]}
            setPayload={setPayload}
            isLoading={isSavedListLoading || isMasterLoading}
            checked={checked}
            setChecked={setChecked}
          />
        </>
      )}

      {type === 'SINGLE_SELECT' && (
        <SingleSelect
          masterTargetingId={id}
          masterList={filteredMasterList}
          savedTargetings={savedTargetings[id]}
          setPayload={setPayload}
          isLoading={isSavedListLoading || isMasterLoading}
          checked={checked}
          setChecked={setChecked}
        />
      )}
      {type === 'INCLUDE_EXCLUDE' && (
        <IncludeExclude
          masterTargetingId={id}
          masterList={filteredMasterList}
          setPayload={setPayload}
          isLoading={isMasterLoading}
          savedTargetings={savedTargetings[id]}
          masterFetchData={data}
        />
      )}
    </>
  );
};
