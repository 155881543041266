import queryString from "query-string";
import { useQuery } from "react-query";
import Services from "./../services";

export const DashboardOverviewSummary = (agencyId,agencyCurrency) => {
    return useQuery(
        ["OVERVIEW_SUMMARY", agencyId],
        async () => {
          const payload = { 
            agencyId,
            currency: agencyCurrency
          };
          const response = await Services.getOverviewDetails(
            queryString.stringify(payload)
          );
          return response.data;
        },
        { enabled: !!agencyId,
          //staleTime: STALE_TIME.HALF_HOUR,
          //cacheTime: CACHE_TIME.HALF_HOUR
        }
      );
  };