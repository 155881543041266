import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './main.scss';
import { Provider } from 'react-redux';
import axios from 'axios';
import { configureStore } from './libs/redux-sdk';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import CookieUtil from './service-utils/cookie-util';
import { AdvertiserProvider } from './context/AdvertiserProvider';
import App from './App';

const logout = () => {
  const { removeAllCookie } = CookieUtil();
  removeAllCookie();
  window.location.replace(`${window.location.origin}/login`);
};

axios.interceptors.response.use(
  function (response) {
    //console.log('responseresponseresponse',response)
    return response;
  },
  function (error) {
    const { status } = error?.response ?? {};
    if ([401].includes(status)) {
      logout();
    }
    return Promise.reject(error);
  },
);

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <Provider store={configureStore()}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <AdvertiserProvider>
            <App />
          </AdvertiserProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </Provider>
  </Suspense>,
);
