import React, { useState } from 'react';
import { Grid, Typography } from '../../common';
import history from '../../history';
import './forgot-password.scss';
import logo from '../../assets/img/adzy.png';
// import loginBg from '../../assets/img/login_bg.jpg';
import Services from '../../service-utils/services';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { ForgotPasswordForm } from '../../components/forgot-password-form';
// import LoginFooter from '../../components/footerforlogin/loginfooter';
// import Footer from '../../hoc/layout/footer';
import OverlapLoader from '../../common/loader/OverlapLoader';
import Slider from '../../components/slider/slider';
import Footer from '../../hoc/layout/footer';

export const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [errorText, setErrorText] = useState();
  const { mutate: handleOnSubmit, isLoading: isLoading } = useMutation(
    async (values) => {
      const { email } = values;
      const payload = {
        email: email,
      };
      const { data } = await Services.forgotPass(payload);
      return data;
    },
    {
      onError: (err) => {
        let errorMsg = err?.response.data.reasonDesc || 'Error in sending email.';
        // enqueueSnackbar(errorMsg, { variant: 'error' });
        setErrorText(errorMsg);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Email sent successfully.', { variant: 'success' });
        history.replace('/login');
      },
    },
  );

  return (
    <div className="login-page">
      <Grid container className="full-height-grid">
        <Grid
          item
          xs={12}
          md={7}
          className="d-flex justify-content-center align-items-center bg-dark1 slider-container"
        >
          <img src={logo} alt="logo" className="img-fluid" />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          className="d-flex justify-content-center align-items-center h-100 login-form-container p-4"
        >
          <div className="text-center w-100">
            <ForgotPasswordForm handleOnSubmit={handleOnSubmit} errorText={errorText} />
          </div>
        </Grid>
      </Grid>

      {isLoading && <OverlapLoader />}
      {/* <Footer /> */}
    </div>
  );
};
