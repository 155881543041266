import React, { useMemo, useEffect } from 'react';
import { Chart } from '../../../components';
import Services from '../../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { isEmpty, orderBy } from 'lodash';
import { CHART_COLOR, getCurrencySymbol } from '../../../common/utils';
import { externalTooltipHandler } from '../../../components/chart/index';
import { getclickCountsData, getimpressionsData, getspendsData } from '../../../common/utils';
import { Button } from './../../../common';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip'; // Import Tooltip

export const ImpressionPlacementWise = ({
  agencyId,
  selectedCampaign,
  payload,
  selectPlacementLimit,
  // handleDownload
}) => {
  const selectedCampaignId = selectedCampaign?.id;
  let startDate = payload?.startDate; // Check if payload exists before accessing properties
  let endDate = payload?.endDate;
  let lineItemIds = payload?.lineItemIds;
  let creativeIds = payload?.creativeIds;
  let detectedDeviceTypes = payload?.detectedDeviceTypes;
  let detectedDeviceOs = payload?.detectedDeviceOs;
  let states = payload?.states;
  let countries = payload?.countries;
  let limit = selectPlacementLimit?.value;
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: chartData = [],
    isLoading: isLoadingChartData,
    refetch,
  } = useQuery(
    [
      'PLACEMENT_WISE_IMPRESSIONS',
      selectedCampaignId,
      startDate,
      endDate,
      detectedDeviceOs,
      agencyId,
      lineItemIds,
      creativeIds,
      detectedDeviceTypes,
      states,
      countries,
      limit,
    ],
    async () => {
      const queryStringParams = {
        startDate,
        endDate,
        limit,
        agencyId,
        ...(lineItemIds && { lineItemIds }),
        ...(creativeIds && { creativeIds }),
        ...(detectedDeviceTypes && { detectedDeviceTypes }),
        ...(detectedDeviceOs && { detectedDeviceOs }),
        ...(states && { states }),
        ...(countries && { countries }),
      };
      const response = await Services.getCampaignPlacement(
        selectedCampaignId,
        queryString.stringify(queryStringParams),
      );

      return response.data?.graphDataList;
    },
    {
      enabled: !!agencyId && !isEmpty(selectedCampaignId) && !!startDate && !!endDate && !!limit,
    },
  );
  useEffect(() => {
    if (startDate && endDate) {
      // Both startDate and endDate have values, trigger API call
      refetch();
    }
  }, [
    startDate,
    lineItemIds,
    detectedDeviceOs,
    detectedDeviceTypes,
    states,
    countries,
    creativeIds,
    endDate,
    limit,
    refetch,
  ]);
  const maxLength = 30; // Define the maximum length for label

  const data = useMemo(() => {
    const sortedData =
      orderBy(
        chartData?.map((item) => ({
          ...item,
          y: item.value,
          trimmedLabel:
            item.label && item.label.length > maxLength
              ? item.label.substring(0, maxLength) + '...'
              : item.label,
        })),
        ['value', 'label'],
        ['desc', 'asc'],
      ) || [];

    const impressions = sortedData.map((item) => item.value);
    let impressionData = getimpressionsData(impressions);

    const spends = sortedData.map((item) => item.totalCost.amount);
    let spendData = getspendsData(spends);

    const clickCounts = sortedData.map((item) => item.clickCount);
    let clickData = getclickCountsData(clickCounts);

    return {
      labels: sortedData?.map((item) => item.trimmedLabel),
      datasets: [
        {
          fill: false,
          label: 'Spend',
          data: spendData,
          actualData: spends,
          backgroundColor: CHART_COLOR.SPEND,
          borderColor: CHART_COLOR.SPEND,
          pointRadius: 0,
          borderWidth: 2,
          type: 'line',
        },
        {
          fill: true,
          label: 'Impressions',
          data: impressionData,
          actualData: impressions,
          backgroundColor: CHART_COLOR.IMPRESSION,
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          fill: true,
          label: 'Click Count',
          data: clickData,
          actualData: clickCounts,
          backgroundColor: CHART_COLOR.CLICK_COUNT,
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
  }, [chartData]);

  const currency = chartData.map((item) => item.totalCost.currency);
  const currencyData = getCurrencySymbol(currency[0]);

  const options = {
    indexAxis: 'y',
    interaction: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem = {}, data) => {
          const {
            dataset: { label = '', actualData = [] },
            dataIndex,
          } = tooltipItem || {};

          let tooltipText = `${label} : ${actualData[dataIndex]}`;

          if (label === 'Spend') {
            tooltipText = `${label} : ${currencyData} ${actualData[dataIndex]}`;
          }

          return tooltipText;
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentile',
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
  };
  const fetchAllDataForDownload = async () => {
    const limit = '10000000';
    const queryStringParams = {
      startDate,
      endDate,
      limit: limit,
      agencyId,
      ...(lineItemIds && { lineItemIds }),
      ...(creativeIds && { creativeIds }),
      ...(detectedDeviceTypes && { detectedDeviceTypes }),
      ...(detectedDeviceOs && { detectedDeviceOs }),
      ...(states && { states }),
      ...(countries && { countries }),
    };
    const response = await Services.getCampaignPlacement(
      selectedCampaignId,
      queryString.stringify(queryStringParams),
    );

    return response.data?.graphDataList;
  };

  const handleDownload = async () => {
    try {
      const allData = await fetchAllDataForDownload();

      if (!allData || allData.length === 0) {
        console.error('No data to download.');
        return;
      }

      const columnNames = ['Placement Name', 'Impressions', 'Clicks', `Spend in ${currency[0]}`];
      const csvData = allData.reduce((acc, row) => {
        const totalCostAmount = row.totalCost?.amount || 0;

        const rowData = [row.label, row.impressionCount || 0, row.clickCount || 0, totalCostAmount]
          .map((value) => (value !== null && value !== undefined ? `"${value}"` : ''))
          .join(',');

        return `${acc}${rowData}\n`;
      }, columnNames.join(',') + '\n');

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedCampaign?.label}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      enqueueSnackbar('Downloaded successfully.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`${error}`, { variant: 'error' });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3 ml-5" style={{ marginTop: '-48px' }}>
        <Tooltip title="Export data">
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38"
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownload}
          ></Button>
        </Tooltip>
      </div>
      <div
        className="horizontal-bar-chart-container"
        // style={{ height: chartHeight, width: chartWidth }}
      >
        <Chart
          type="Bar"
          chartData={data}
          options={options}
          // height={chartHeight}
          // width={chartWidth}
        />
      </div>
    </>
  );
};
