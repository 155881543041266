import React, { useEffect, useState, useMemo } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table } from '../../components';
import { Button, Loader } from '../../common';
import AddIcon from '@material-ui/icons/Add';
import getSessionData from './../../service-utils/session-util';
import { useHistory, useLocation } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { Dropdown } from 'react-bootstrap';
import Highcharts from 'highcharts';
import SpeedIcon from '@material-ui/icons/Speed';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { LINE_ITEM_BIDDING_STRATEGY } from '../../libs/constants';
import { handleMsgOnForm, getCurrencySymbol } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { useSnackbar } from 'notistack';
import bullet from 'highcharts/modules/bullet.js';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import budgetIcon from '../../assets/icon/budget.png';
import spendIcon from '../../assets/icon/spend.png';
import adsClick from '../../assets/icon/adsClick.png';
import { GetCampaignData } from './../../service-utils/useApis';
import { numberFormatter } from './../../common/utils';
import { isEmpty } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './line-item.scss';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import ObjectivePopUp from '../objective-popup/objective-popup';
import { Modal, Tooltip } from '@material-ui/core';
import { EVENT_URL } from '../../common/constants';
import CreateIcon from '../../components/create-icon/createIcon';
import { CloneButton, DeleteButton, EditButton } from '../../components/react-icons/ReactIcons';

bullet(Highcharts);

export const LineItem = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [deleteId, setDeleteId] = useState();
  //const [searchText,setSearchText] = useState('')
  const [conversion, setConversion] = useState();
  const [linkToCopy, setLinkToCopy] = useState('');
  const queryParams = queryString.parse(location.search);
  const { campaignId } = queryParams;
  let { agencyId, agencyCurrency } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [viewMode, setViewMode] = useState('server'); // Default view mode
  const { data: campaignData = {} } = GetCampaignData(
    campaignId,
    advertiserId,
    agencyId,
    agencyCurrency,
  );

  const { data, isLoading, refetch } = useQuery(
    ['LINE_ITEM_DATA', pageLimit, pageNumber, agencyId],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyCurrency,
        agencyId,
      };
      const response = await Services.lineItemSearch(
        advertiserId,
        campaignId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId && !!campaignId },
  );

  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.lineItemDelete(id, query);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Line item deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );

  const tableData = useMemo(() => {
    return data?.lineItemDTO || [];
  }, [data]);

  const handleAddLineItem = () => {
    const endDateTime = campaignData?.endDateTime;
    const dateParts = endDateTime.split(' ');
    const [day, month, year] = dateParts[0].split('/');
    const [hours, minutes] = dateParts[1].split(':');
    const campaignEndDate = new Date(year, month - 1, day, hours, minutes);
    const currentDate = new Date();

    if (campaignEndDate < currentDate) {
      enqueueSnackbar(
        'This campaign has ended. Please create a new campaign to create new Line Item.',
        {
          variant: 'warning',
        },
      );
    } else {
      history.push(
        `/advertiser/line-item-create?advertiserId=${advertiserId}&campaignId=${campaignId}&timezone=${campaignData?.timezone}`,
      );
    }
  };

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/campaign',
        },
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Line Items
          </div>
        ),
      }),
    );
  }, [dispatch]);

  let eCPC = campaignData.totalClick
    ? campaignData.totalSpent?.amount / campaignData.totalClick
    : 0;
  let eCPM = campaignData.impressionsDelivered
    ? (campaignData.totalSpent?.amount * 1000) / campaignData.impressionsDelivered
    : 0;

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleEdit = (id) => {
    if (id) {
      history.push(
        `/advertiser/line-item/${id}?advertiserId=${advertiserId}&campaignId=${campaignId}&isEdit=true#0`,
      );
    }
  };
  const handleClone = (id) => {
    if (id) {
      history.push(`/advertiser/line-item-clone/${id}?campaignId=${campaignId}`);
    }
  };

  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <Dropdown.Item onClick={() => handleClone(row.id)}>
              <CloneButton handleApply={() => handleClone(row.id)} />{' '}
              <span className="primary-color">Clone</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleEdit(row.id)}>
              <EditButton handleEdit={() => handleEdit(row.id)} />{' '}
              <span className="primary-color">Edit</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteId(row.id)}>
              <DeleteButton handleDelete={() => setDeleteId(row.id)} />{' '}
              <span className="deleteColor">Delete</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    // setOpen(true);
    history.push(
      `/advertiser/line-item-create?advertiserId=${advertiserId}&campaignId=${campaignId}&timezone=${campaignData?.timezone}`,
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleIcons = (row) => {
  //   const { id } = row;
  //   return (
  //     <div className="flex items-center gap-1">
  //       <CloneButton handleApply={() => handleClone(id)} />
  //       <EditButton handleEdit={() => handleEdit(id)} />
  //       <DeleteButton handleDelete={() => setDeleteId(id)} />
  //     </div>
  //   );
  // };
  const SummaryBox = (props) => {
    const { title = '', icon, image, value = '', valuesData, titles, tooltipValue } = props;
    return (
      <Card className="d-flex p-2">
        <div className="d-flex w-100 align-items-center">
          <div className="w-25 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>
          <div className="w-75">
            <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
              <CardContent className="p-2 mr-5">
                <div className="filters d-flex justify-content-between">
                  <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
                  <Typography className="mr-2 h4 font30">{value || ''}</Typography>
                </div>

                <div className="dotted-line font20 summaryBoxBorder"></div>
                <div className="filters d-flex justify-content-between">
                  <Typography className="h4 mt-1 font30">{valuesData || ''}</Typography>
                  <Typography className="mt-2 font20 summarytitleColor">{titles || ''}</Typography>
                </div>
              </CardContent>
            </Tooltip>
          </div>
        </div>
      </Card>
    );
  };

  const getTableHeader = [
    //{ dataField: 'id', text: 'ID' },

    {
      dataField: '',
      formatter: (col, row) => (
        <div style={{ position: 'absolute', top: 0 }}>
          {row.expired && <div className="cross-tape">Expired</div>}
        </div>
      ),
    },
    {
      dataField: 'name',
      text: <div className="ml-3">Line Item</div>,
      formatter: (col, row) => (
        <div>
          <a
            href={`/advertiser/line-item/${row.id}?advertiserId=${advertiserId}&campaignId=${campaignId}&isEdit=true#0`}
          >
            {col}
          </a>
        </div>
      ),
    },
    {
      dataField: 'pacing',
      text: 'Current Pacing',
      formatter: (col) => (
        <div>
          <SpeedIcon />
          <span className="ml-2">{col || 0}</span>
        </div>
      ),
    },
    {
      dataField: 'biddingStrategy',
      text: 'Bidding Strategy',
      formatter: (col) => LINE_ITEM_BIDDING_STRATEGY.filter((item) => item.value === col)[0]?.label,
    },
    { dataField: 'startDateTime', text: 'Start Date' },
    { dataField: 'endDateTime', text: 'End Date' },
    // { dataField: 'timezone', text: 'Timezone' },
    {
      dataField: 'impressionGoal',
      text: 'Impression Goal',
      formatter: (col) => (col !== null ? col : '-'),
    },
    {
      dataField: 'spendGoal',
      text: 'Spend Goal',
      formatter: (col) => (col !== null ? col : '-'),
    },

    {
      dataField: 'deliveredImpressions',
      text: 'Delivered Impressions',
      formatter: (col) => (col !== null ? col : '0'),
    },
    {
      dataField: 'deliveredSpend',
      text: 'Delivered Spend',
      formatter: (col) => (col !== null ? col.toFixed(2) : '0'),
    },
    { dataField: 'cpm', text: 'CPM', formatter: (col) => (col !== null ? col : '-') },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
    },
    { dataField: 'id', text: '', formatter: (col, row) => manageButton(row) },
    // { dataField: 'id', text: '', formatter: (col, row) => handleIcons(row) },
  ];
  //   function kFormatter(num) {
  //     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  // }
  const handleButtonClick = () => {
    handleOpen(true);
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        {/*
      <SearchInput onChange={(e) => {setSearchText(e.target.value)}} value={searchText}/>
        <Button variant="contained" className="btn btn-primary d-flex btn-38 ml-3" startIcon={<FilterListIcon />}
        onClick={handleApply}>
          Apply
        </Button>
      */}
        <div className="w-75">
          <div class="col inline-left-div">
            {' '}
            <h3>Selected Campaign :</h3>
            <span class="campaign-name">{campaignData?.name && `${campaignData?.name}`}</span>
          </div>
          <div class="col inline-left-div">
            <h3>Start/End Date :</h3>
            {campaignData?.startDateTime &&
              campaignData?.endDateTime &&
              ` ${campaignData?.startDateTime} - ${campaignData?.endDateTime} `}
          </div>
          <div class="col inline-left-div">
            <h3>Zone :</h3>
            {campaignData?.timezone && `${campaignData?.timezone}`}
          </div>
          {/* {campaignData?.conversionTracker ? (
            <div className="col inline-left-div mt-2 cursor-pointer ">
              <h3 onClick={() => handleConversionTracking(campaignId)}>
                <InfoIcon
                  onClick={() => handleConversionTracking(campaignId)}
                  className="primary-color"
                />{' '}
                Conversion Tracking
              </h3>
            </div>
          ) : null} */}
        </div>

        {/* <button style={{ height: '40px' }} className="btn btn-primary" onClick={handleOpen}>
          {/* <AddIcon /> */}
        {/* New Line Item */}
        {/* </button> */}
        <div>
          <CreateIcon label="Create New Line Item" handleAdd={handleOpen} />
        </div>

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ObjectivePopUp
            campaignId={campaignId}
            open={open}
            handleClose={handleClose}
            advertiserId={advertiserId}
            agencyId={agencyId}
            timezone={campaignData?.timezone}
            currency={agencyCurrency}
          />
        </Modal> */}
      </div>
      <br />
      <div className="summary-box mt-0 mb-3">
        <Grid container spacing={3}>
          <Grid item xs={4} className="d-grid ">
            <SummaryBox
              title="Impressions"
              icon={<RemoveRedEyeIcon className="font50 primary-color" />}
              value={`${numberFormatter(campaignData?.impressionsDelivered || 0)}`}
              valuesData={
                `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                  eCPM,
                )}` || 0
              }
              titles="eCPM"
              tooltipValue={`Impressions: ${campaignData?.impressionsDelivered || 0}`}
            />
          </Grid>
          <Grid item xs={4} className="d-grid">
            <SummaryBox
              title="Clicks"
              icon={<TouchAppIcon className="font50 primary-color" />}
              value={` ${numberFormatter(campaignData?.totalClick || 0)}`}
              valuesData={`${campaignData?.ctr !== undefined ? `${campaignData.ctr}%` : '0%'}`}
              titles="CTR"
              tooltipValue={`Clicks: ${campaignData?.totalClick || 0} `}
            />
          </Grid>

          <Grid item xs={4} className="d-grid ">
            <SummaryBox
              title="Total Spent"
              image={spendIcon}
              value={
                `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                  campaignData?.totalSpent?.amount,
                )}` || 0
              }
              valuesData={
                `${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${numberFormatter(
                  eCPC,
                )}` || 0
              }
              titles="eCPC"
              tooltipValue={
                `Total Spent: ${getCurrencySymbol(campaignData?.totalSpent?.currency)} ${
                  campaignData?.totalSpent?.amount
                }` || 0
              }
            />
          </Grid>
        </Grid>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible line-item-table"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new line item"
          additionalText="Create Line Item to proceed with the steps of getting your advertisement ready!"
        />
      )}
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDelete(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this line item?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
