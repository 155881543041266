import React, { Suspense, lazy, useState } from 'react';
import desktop from './../../../assets/img/desktop.png';
import TrendingStories from './../../../assets/img/TrendingStories.png';
import webMd320x320 from './../../../assets/img/webMd320x320.jpg';
import mobile_frame from './../../../assets/img/mobile_frame.png';
import tablet_frame from './../../../assets/img/tablet_frame.png';
import tabsharekaro from './../../../assets/img/tabsharekaro.png';
import smartphoneicon from './../../../assets/icon/smartphoneicon.png';
import desktopicon from './../../../assets/icon/desktopicon.png';
import tableticon from './../../../assets/icon/tableticon.png';
const LazyImage = lazy(() => import('./LazyImage'));
const BANNER_320_320 = ({ imageUrl = '' }) => {
  // const [currentView, setCurrentView] = useState('mobile');

  // const handleViewChange = (view) => {
  //   setCurrentView(view);
  // };
  return (
    <>
      {/* <div className="view-buttons">
        <button
          className={`btn1 d-flex1 ${currentView === 'mobile' ? 'selected' : ''}`}
          onClick={() => handleViewChange('mobile')}
        >
          <img src={smartphoneicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'desktop' ? 'selected' : ''}`}
          onClick={() => handleViewChange('desktop')}
        >
          <img src={desktopicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'tablet' ? 'selected' : ''}`}
          onClick={() => handleViewChange('tablet')}
        >
          <img src={tableticon} alt="" className="icons" />
        </button>
      </div> */}
      {/* {mobile_frame && currentView === 'mobile' && ( */}
      <div className="BANNER_320_320 mobile_app_wrapper">
        <img src={webMd320x320} alt="banner" className="app_image" />
        <img src={mobile_frame} alt="mobile" className="frame_image w-100" />
        <Suspense>
          <LazyImage imageUrl={imageUrl} />
        </Suspense>
      </div>
      {/* )} */}
      {/* {desktop && currentView === 'desktop' && (
        <div className="BANNER_320_320 desktop_app_wrapper">
          <img src={TrendingStories} alt="banner" className="app_image1" />
          <img src={desktop} alt="desktop" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case2" />
          </Suspense>
        </div>
      )} */}
      {/* {tablet_frame && currentView === 'tablet' && (
        <div className="BANNER_320_320 tablet_app_wrapper">
          <img src={tabsharekaro} alt="banner" className="app_image2" />
          <img src={tablet_frame} alt="tablet" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case3" />
          </Suspense>
        </div>
      )} */}
    </>
  );
};

export default BANNER_320_320;
