import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { NewSelect, Typography, Button, Loader } from '../../common';
import Services from '../../service-utils/services';
import { CONTENT_URL } from '../../common/constants';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { handleMsgOnForm } from '../../common/utils';
import { useSnackbar } from 'notistack';
import { isEmpty, uniqBy, orderBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../assets/img/adzy_large.png';
import Tooltip from '@material-ui/core/Tooltip';
import history from '../../history';
import { CACHE_TIME, STALE_TIME } from './../../common/constants';
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { getSessionData } from '../../service-utils';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderBottom: '1px solid #dee2e6',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    width: '30px',
    height: '30px',
  },
  title: {
    'max-width': '110px',
    // 'text-overflow': 'ellipsis',
    // 'white-space': 'nowrap',
    // overflow: 'hidden',
    wordWrap: 'break-word',
  },
}));

export const CreativeForm = ({
  campaignId,
  advertiserId,
  lineItemId,
  agencyId,
  expired = false,
  lineItemObjective,
  goToNextStep = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [creativeOptions, setCreativeOptions] = useState([]);
  const [selectedCreatives, setSelectedCreatives] = useState([]);
  const [expanded, setExpanded] = useState({});
  const { tncUpdated } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});
  let history = useHistory();
  const handleExpandClick = (id) => {
    setExpanded((oldState) => {
      const selectedId = id.toString();
      if (Object.keys(oldState).includes(selectedId)) {
        return {
          ...oldState,
          [selectedId]: !oldState[selectedId],
        };
      } else {
        return {
          ...oldState,
          [selectedId]: true,
        };
      }
    });
  };

  const { data: allCreatives = [], isLoading: isLoadingAllCreatives } = useQuery(
    ['ALL_CREATIVES', advertiserId],
    async () => {
      const queryStringParams = {
        // advertiserId,
        agencyId,
        published: true,
        pageNum: 0,
        pageSize: 5000,
      };
      const response = await Services.creativeSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data?.creativeDTOList || [];
    },
    {
      enabled: !!agencyId && !!advertiserId,
    },
  );

  const { data: lineitemCreatives = [], isLoading: isLoadingLineitemCreatives } = useQuery(
    ['LINEITEM_CREATIVES', lineItemId, advertiserId, campaignId],
    async () => {
      const response = await Services.getCreativesForLineItem(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
      );
      return !isEmpty(response.data?.creativeDTOList)
        ? uniqBy(response.data?.creativeDTOList, 'id')
        : [];
    },
    {
      enabled: !!agencyId && !!lineItemId,
    },
  );
  const { data: bannerSizes = [] } = useQuery(
    ['MASTER_BANNER_SIZES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterBannerSizes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );
  // console.log('bannerSizes', bannerSizes);

  useEffect(() => {
    if (!isEmpty(allCreatives) && !isEmpty(lineitemCreatives)) {
      //Object.keys(allCreatives)
      //Object.keys(lineitemCreatives).map()
      setSelectedCreatives(
        lineitemCreatives.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      );
      //console.log(lineitemCreatives,allCreatives)
    }
  }, [allCreatives, lineitemCreatives]);

  const { mutate: updateCreatives, isLoading: isUpdatingCreatives } = useMutation(
    async (creativeIds) => {
      ///dsp-api/v1/lineItem/{lineItemId}/addCreative/{creativeId}
      const payload = creativeIds?.map((item) => item.id) || [];
      const response = await Services.addCreativeToLineItem(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
        payload,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Updated successfully.', { variant: 'success' });
      },
    },
  );

  const updateAllCreatives = useCallback(() => {
    //if(!isEmpty(selectedCreatives)){
    //selectedCreatives.forEach((item)=>{
    updateCreatives(selectedCreatives);
    //})
    //}
  }, [selectedCreatives]);

  useEffect(() => {
    if (!isEmpty(allCreatives)) {
      setCreativeOptions(
        allCreatives.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, [allCreatives]);
  // console.log('selectedCreatives', selectedCreatives);
  const tncLoader = tncUpdated === 'true' && isUpdatingCreatives;

  return (
    <>
      {isLoadingAllCreatives || isLoadingLineitemCreatives ? (
        <Loader />
      ) : (
        <div className="pt-3 p-4 mn-h-200 LI-creation-tab">
          <NewSelect
            info="Select creatives you want to display on ad banners"
            options={creativeOptions}
            value={selectedCreatives}
            onChange={(obj) => setSelectedCreatives(obj)}
            placeholder={`Select Creative`}
            isMulti={true}
            className="py-2"
          />

          <div>
            {!isEmpty(selectedCreatives) ? (
              <div className="row pl-2">
                <Typography variant="subtitle1" className="lh-1 mb-3">
                  Selected Creatives:
                </Typography>
                {/* {lineItemObjective === 'LEAD' && (
                  <Typography
                    variant="body2"
                    component="p"
                    className="lh-1 mb-3"
                    style={{ color: 'red' }}
                  >
                    Note:- Since the line item objective is Lead Generation. On click users will be
                    routed to Lead form instead of Landing pages in the selected creatives.
                  </Typography>
                )} */}
                {selectedCreatives.map((item) => {
                  return (
                    <div className="col-3 mb-3">
                      <Card className="">
                        <CardMedia
                          className={classes.media}
                          image={
                            item?.bannerDTO?.bannerUrl
                              ? `${CONTENT_URL}/${item?.bannerDTO?.bannerUrl}`
                              : logo
                          }
                          title={item.name}
                        />
                        <CardHeader
                          avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                              {!isEmpty(item?.bannerDTO) ? 'B' : 'V'}
                            </Avatar>
                          }
                          action={
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded[item?.id],
                              })}
                              onClick={() => handleExpandClick(item?.id)}
                              aria-expanded={expanded[item?.id]}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          }
                          title={
                            <div
                              className={`${classes.title} ${
                                item.name.length > 7 ? classes.titleWrap : ''
                              }`}
                            >
                              {item.name}
                            </div>
                          }
                        />

                        {item?.bannerDTO?.externalImpressionTrackerUrl ||
                        item?.bannerDTO?.landingPageUrl ? (
                          <Collapse in={expanded[item?.id]} timeout="auto" unmountOnExit>
                            <CardContent>
                              {item?.bannerDTO?.externalImpressionTrackerUrl && (
                                <>
                                  <Typography variant="h6" className="lh-1 small">
                                    External Impression Tracker Url:
                                  </Typography>
                                  <Tooltip
                                    title={item?.bannerDTO?.externalImpressionTrackerUrl}
                                    arrow
                                  >
                                    <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                      {item?.bannerDTO?.externalImpressionTrackerUrl}
                                    </Typography>
                                  </Tooltip>
                                </>
                              )}
                              {item?.bannerDTO?.landingPageUrl && (
                                <>
                                  <Typography variant="h6" className="lh-1 small">
                                    Landingpage Url:
                                  </Typography>
                                  <Tooltip title={item?.bannerDTO?.landingPageUrl} arrow>
                                    <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                      {item?.bannerDTO?.landingPageUrl}
                                    </Typography>
                                  </Tooltip>
                                </>
                              )}
                              {item?.bannerDTO?.bannerSizeId && (
                                <>
                                  <Typography variant="h6" className="lh-1 small">
                                    Banner Size:
                                  </Typography>
                                  <Tooltip title={item?.bannerDTO?.landingPageUrl} arrow>
                                    <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                      {item?.bannerDTO?.masterBannerSizeDTO.formatName} Size
                                    </Typography>
                                  </Tooltip>
                                </>
                              )}
                              {/* {bannerSizes?.map((ele) => {
                                if (item?.bannerDTO?.bannerSizeId === ele.key) {
                                  return (
                                    <div key={ele.key}>
                                      <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                        {ele.label}
                                      </Typography>
                                    </div>
                                  );
                                }
                                return null; // Return null if the condition is not met
                              })} */}

                              <a
                                href={`/advertiser/creative/view-creatives/${item.id}?advertiserId=${advertiserId}&isClosed=true`} // Specify the target route
                                target="_blank" // Open in a new tab
                                rel="noopener noreferrer" // Add this for security reasons when using target="_blank"
                              >
                                View Image
                              </a>
                            </CardContent>
                          </Collapse>
                        ) : null}
                      </Card>
                    </div>
                  );
                })}

                <div className="mt-4 d-flex justify-content-end ">
                  <Button
                    variant="contained"
                    className="btn btn-secondary d-flex"
                    onClick={history.goBack}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    className="btn btn-secondary d-flex ml-4"
                    onClick={() => {
                      goToNextStep(lineItemId);
                    }}
                    disabled={expired}
                  >
                    Next
                  </Button>

                  <Button
                    disabled={expired}
                    variant="contained"
                    type="submit"
                    className="btn btn-primary d-flex ml-4"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateAllCreatives();
                      goToNextStep(lineItemId);
                    }}
                  >
                    {tncLoader ? 'Submiting' : 'Submit'}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center  mn-h-200 ">
                  <Typography variant="subtitle1" className="lh-1 mb-1 center">
                    Please Select creatives
                  </Typography>
                </div>
                <div className="mt-4 d-flex justify-content-end ">
                  <Button
                    variant="contained"
                    className="btn btn-secondary d-flex"
                    onClick={history.goBack}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="btn btn-secondary d-flex ml-4"
                    onClick={() => {
                      goToNextStep(lineItemId);
                    }}
                    disabled={expired}
                  >
                    Next
                  </Button>
                  <Button
                    disabled={expired}
                    variant="contained"
                    type="submit"
                    className="btn btn-primary d-flex ml-4"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateAllCreatives();
                      goToNextStep(lineItemId);
                    }}
                  >
                    {tncLoader ? 'Submiting' : 'Submit And Next'}
                  </Button>
                </div>
                {tncLoader && <OverlapLoader />}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
