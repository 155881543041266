import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  NewTextField,
  Paper,
  Grid,
  Button,
  Typography,
  NewDatePicker,
  NewSelect,
} from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Services from '../../service-utils/services';
import { useMutation, useQuery } from 'react-query';
import { dateFormatMoment } from '../../components/format-date-moment';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import moment from 'moment';
import { isEmpty, orderBy } from 'lodash';
import { GetCampaignData } from '../../service-utils/useApis';
import OverlapLoader from '../../common/loader/OverlapLoader';

const LineItemClone = () => {
  const dispatch = useDispatch();
  const { lineItemId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const hash = location?.hash;
  let { agencyId, advertiserId, tncUpdated } = getSessionData();
  const queryParams = queryString.parse(location?.search);
  const { campaignId = '' } = queryParams;
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [cappedDaysCountFromStorage, setCappedDaysCount] = useState(0);
  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);
  const { data: campaignData = {} } = GetCampaignData(
    selectedCampaign?.campaignId || campaignId,
    advertiserId,
    agencyId,
  );
  const { data: objectiveData = [], isLoading } = useQuery(
    ['OBJECTIVE_DATA', agencyId],
    async () => {
      const response = await Services.getMasterObjectives(agencyId);
      return response?.data?.masterObjectiveList || [];
    },
    { enabled: !!agencyId },
  );

  const { data: lineItemData = {} } = useQuery(
    ['LINE_ITEM_DATA', lineItemId, agencyId],
    async () => {
      const payload = {
        agencyId,
      };

      const response = await Services.lineItemGetDetails(
        advertiserId,
        campaignId,
        lineItemId,
        queryString.stringify(payload),
      );
      return response.data?.lineItemDTO;
    },
    { enabled: !!lineItemId && !!agencyId },
  );

  const saveCpmAndCtrMutationFn = async (lineItemId) => {
    const cpm =
      campaignData.budget?.currency === 'INR' ? selectedObjective.cpmInr : selectedObjective.cpm;
    const cpmCtrPayload = {
      lineItemId,
      cpm: cpm,
      ctr: selectedObjective?.ctr,
      targetedCpm: null,
      targetedCtr: null,
    };
    const query = queryString.stringify({ agencyId });
    return await Services.lineItemOptimization(
      advertiserId,
      campaignId,
      lineItemId,
      query,
      cpmCtrPayload,
    );
    return Promise.resolve();
  };

  const { mutate: saveCpmAndCtr } = useMutation(saveCpmAndCtrMutationFn, {
    onError: (err) => {
      // enqueueSnackbar('Failed to save CPM and CTR.', { variant: 'error' });
      console.log(err);
    },
    onSuccess: () => {
      // enqueueSnackbar('CPM and CTR saved successfully.', { variant: 'success' });
    },
  });

  const {
    data: allData = {},
    isLoading: isLoadingAllData,
    error: dataError,
  } = useQuery(
    ['ALL_DATA', agencyId, advertiserId],
    async () => {
      const advertiserQueryStringParams = {
        pageSize: 5000,
        pageNum: 0,
        agencyId,
      };
      const advertiserResponse = await Services.getmasterADCampaign(
        advertiserId,
        queryString.stringify(advertiserQueryStringParams),
      );
      const advertiser = advertiserResponse.data?.advertiser || [];
      return {
        advertiser,
        campaignMasterList,
      };
    },
    {
      enabled: !!agencyId,
    },
  );

  const { advertiser, campaignMasterList } = allData;

  const { mutate: cloneLineItem, isLoading: isLoadingCloneLineItem } = useMutation(
    async (values) => {
      const { name, startDate, endDate } = values;
      const payload = {
        campaignId: selectedCampaign?.campaignId || null,
        name: name,
        startDateTime: dateFormatMoment(startDate, 'DD/MM/yyyy HH:mm'),
        endDateTime: dateFormatMoment(endDate, 'DD/MM/yyyy HH:mm'),
        lineItemObjective: selectedObjective?.name || null,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.lineItemClone(
        advertiserId,
        campaignId,
        lineItemId,
        query,
        payload,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        const savedlineItemId = data?.lineItemDTO?.id;

        if (savedlineItemId) {
          saveCpmAndCtr(savedlineItemId); // Trigger the mutation to save CPM and CTR
        }
        enqueueSnackbar('Line Item cloned successfully.', { variant: 'success' });
        history.push(`/advertiser/line-item?advertiserId=${advertiserId}&campaignId=${campaignId}`);
      },
    },
  );

  const startDateTime = useMemo(() => {
    return moment(campaignData.startDateTime, 'DD/MM/yyyy HH:mm').toDate();
  }, [campaignData]);

  const endDateTime = useMemo(() => {
    return moment(campaignData.endDateTime, 'DD/MM/yyyy HH:mm').toDate();
  }, [campaignData]);

  useEffect(() => {
    const currentDateAndTime = moment();
    const timeDifference = endDateTime - currentDateAndTime;
    const numberOfDays = timeDifference / (1000 * 3600 * 24);
    const numberOfDaysRounded = Math.round(numberOfDays);
    const cappedDaysCount = numberOfDaysRounded > 8 ? 8 : numberOfDaysRounded;
    setCappedDaysCount(cappedDaysCount);
  }, [endDateTime]);

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: lineItemData?.name || '',
      startDate: moment().add(20, 'minutes'),
      endDate: moment().add(cappedDaysCountFromStorage, 'days'),
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Line Item name is required.'),
    }),
    onSubmit: () => cloneLineItem(values),
  });

  useEffect(() => {
    if (lineItemData?.name) {
      const newName = `${lineItemData.name}_Copy`;
      setFieldValue('name', newName);
      setFieldValue('endDate', moment().add(cappedDaysCountFromStorage, 'days').toDate());
      const objective = objectiveData.find((obj) => obj.name === lineItemData.lineItemObjective);
      setSelectedObjective(objective || null);
    }
  }, [lineItemData, setFieldValue, cappedDaysCountFromStorage]);

  useEffect(() => {
    const campaigns = allData.advertiser?.campaignMasterList || [];

    if (!isEmpty(campaigns)) {
      const activeCampaigns = orderBy(
        campaigns.map((item) => ({
          ...item,
          label: item.campaignName,
          value: item.campaignId,
        })),
        [(campaign) => campaign.label.toLowerCase()],
        ['asc'],
      );
      setCampaignOptions(activeCampaigns);

      const defaultCampaign = activeCampaigns.find(
        (campaign) => campaign.value === campaignData.id,
      );
      setSelectedCampaign(defaultCampaign);
    } else {
      setCampaignOptions([]);
      setSelectedCampaign(null);
    }
  }, [allData.advertiser]);

  const handleSelectCampaign = (selectedOption) => {
    if (selectedOption && selectedOption.value === selectedCampaign?.value) {
      setSelectedCampaign(null);
    } else {
      setSelectedCampaign(selectedOption);
    }
  };

  const handleSelectObjective = (selectedOption) => {
    if (selectedOption) {
      setSelectedObjective({
        label: selectedOption?.label,
        name: selectedOption?.name,
        value: selectedOption?.value,
        ctr: selectedOption?.ctr,
        cpm: selectedOption?.cpm,
        cpmInr: selectedOption?.cpmInr,
      });
    } else {
      setSelectedObjective(null);
    }
  };
  const tncLoader = tncUpdated === 'true' && isLoadingCloneLineItem;

  return (
    <div>
      <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Clone Line Item
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Line Item Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  value={values.name}
                  variant="outlined"
                />
                {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
              </Grid>

              <Grid item xs={12} sm={6} className="mt-3 py-0 d-flex filters">
                <NewSelect
                  options={campaignOptions}
                  value={selectedCampaign}
                  onChange={handleSelectCampaign}
                  placeholder={`Select Campaign`}
                  isMulti={false}
                  className="mt-n3 ml-3"
                  disabled={isLoadingAllData}
                />
              </Grid>

              <Grid item xs={6} sm={3} className="py-0">
                <NewDatePicker
                  placeholder={`Start Date`}
                  isSingleDate={true}
                  required
                  initialValue={values.startDate}
                  timePicker={true}
                  onCallback={(date) => setFieldValue('startDate', date)}
                  key={JSON.stringify({
                    val: values.startDate,
                    maxDate: endDateTime,
                    minDate: startDateTime,
                  })}
                  maxDate={endDateTime}
                  minDate={new Date()}
                />
                {serverErrors.startDateTime && (
                  <p className="text-danger mt-n2">{serverErrors.startDateTime}</p>
                )}
              </Grid>

              <Grid item xs={6} sm={3} className="py-0">
                <NewDatePicker
                  placeholder={`End Date`}
                  isSingleDate={true}
                  required
                  initialValue={values.endDate}
                  timePicker={true}
                  maxDate={endDateTime}
                  minDate={new Date()}
                  onCallback={(date) => setFieldValue('endDate', date)}
                  key={JSON.stringify({
                    val: values.endDate,
                    maxDate: endDateTime,
                    minDate: startDateTime,
                  })}
                />
                {serverErrors.endDateTime && (
                  <p className="text-danger mt-n2">{serverErrors.endDateTime}</p>
                )}
              </Grid>

              <Grid item xs={12} sm={6} className="mt-3 py-0 d-flex filters">
                <NewSelect
                  options={objectiveData.map((obj) => ({
                    label: obj?.label,
                    name: obj?.name,
                    value: obj?.label,
                    ctr: obj?.ctr,
                    cpm: obj?.cpm,
                    cpmInr: obj?.cpmInr,
                  }))}
                  value={
                    selectedObjective
                      ? {
                          label: selectedObjective?.label,
                          name: selectedObjective?.name,
                          value: selectedObjective?.value,
                          ctr: selectedObjective?.ctr,
                          cpm: selectedObjective?.cpm,
                          cpmInr: selectedObjective?.cpmInr,
                        }
                      : null
                  }
                  onChange={handleSelectObjective}
                  placeholder={`Select Objective`}
                  isMulti={false}
                  className="mt-n3 ml-3"
                  disabled={isLoadingAllData}
                />
              </Grid>
            </Grid>

            <div className="mt-4 d-flex justify-content-end">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                onClick={history.goBack}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                {tncLoader ? 'Creating' : 'Create'}
              </Button>
            </div>
            {tncLoader && <OverlapLoader />}
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default LineItemClone;
