import { useQuery } from 'react-query';
import Services from './../services';

export const DashboardOverviewSummaryForAdv = (agencyId, advertiserId, agencyCurrency) => {
  return useQuery(
    ['OVERVIEW_SUMMARY_FOR_ADV', agencyId, advertiserId],
    async () => {
      const response = await Services.getOverviewDetailsByAdv(
        agencyId,
        advertiserId,
        agencyCurrency,
      );
      return response.data;
    },
    {
      enabled: !!agencyId,
      //staleTime: STALE_TIME.HALF_HOUR,
      //cacheTime: CACHE_TIME.HALF_HOUR
    },
  );
};
