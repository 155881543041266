import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewDatePicker } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';

import { dateFormatMoment } from '../../components/format-date-moment';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
const CampaignClone = () => {
  const { campaignId } = useParams();

  let { agencyId, agencyCurrency, advertiserId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/campaign',
        },
      }),
    );
  }, [dispatch]);

  const { data: campaignData = {} } = useQuery(
    ['CAMPAIGN_DATA', campaignId, agencyId, advertiserId],
    async () => {
      const payload = {
        agencyId,
        currency: agencyCurrency,
      };
      const response = await Services.campaignGetDetails(
        campaignId,
        advertiserId,
        queryString.stringify(payload),
      );
      return response.data?.campaignDTO;
    },
    { enabled: !!campaignId && !!agencyId },
  );
  const { mutate: cloneCampaign, isLoading: isLoadingCloneCampaign } = useMutation(
    async (values) => {
      const { name, startDate, endDate } = values;
      const payload = {
        name: name,
        startDateTime: dateFormatMoment(startDate, 'DD/MM/yyyy HH:mm'),
        endDateTime: dateFormatMoment(endDate, 'DD/MM/yyyy HH:mm'),
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.campaignClone(advertiserId, campaignId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Campaign clone successfully.', { variant: 'success' });
        history.push(`/advertiser/campaign`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: campaignData?.name || '',
      startDate: moment().add(20, 'minutes'),
      endDate: moment().add(15, 'days'),
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Campaign name is required.'),
    }),
    onSubmit: () => cloneCampaign(values),
  });
  useEffect(() => {
    if (campaignData?.name) {
      const newName = `${campaignData.name}_Copy`;
      setFieldValue('name', newName);
    }
  }, [campaignData, setFieldValue]);
  const tncLoader = tncUpdated === 'true' && isLoadingCloneCampaign;

  return (
    <div>
      <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Clone Campaign
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} className="py-0">
                <NewTextField
                  required
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Campaign Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  value={values.name}
                  variant="outlined"
                />
                {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
              </Grid>

              <Grid item xs={6} sm={3} className="py-0">
                <NewDatePicker
                  placeholder={`Start Date`}
                  isSingleDate={true}
                  required
                  initialValue={values.startDate}
                  timePicker={true}
                  onCallback={(date) => setFieldValue('startDate', date)}
                  key={values.startDate}
                  minDate={moment()}
                />
                {serverErrors.startDateTime && (
                  <p className="text-danger mt-n2">{serverErrors.startDateTime}</p>
                )}
              </Grid>
              <Grid item xs={6} sm={3} className="py-0">
                <NewDatePicker
                  placeholder={`End Date`}
                  isSingleDate={true}
                  required
                  initialValue={values.endDate}
                  timePicker={true}
                  minDate={moment()}
                  onCallback={(date) => setFieldValue('endDate', date)}
                  key={values.endDate}
                />
                {serverErrors.endDateTime && (
                  <p className="text-danger mt-n2">{serverErrors.endDateTime}</p>
                )}
              </Grid>
            </Grid>
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                class="btn btn-secondary d-flex"
                onClick={() => history.push(`/advertiser/campaign`)}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                {tncLoader ? 'Creating' : 'Create'}
              </Button>
              {tncLoader && <OverlapLoader />}
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default CampaignClone;
