import React, { cloneElement } from 'react';
import { DisableOverlayTrigger } from './DisableOverlayTrigger';

export const HasPermission = ({
  permission = true,
  children,
  errorProps = null,
  disableOverlay = null,
}) => {
  if (!permission && errorProps) {
    if (disableOverlay)
      return cloneElement(
        <DisableOverlayTrigger overlay={disableOverlay}>{children}</DisableOverlayTrigger>,
        { ...errorProps },
      );
    else
      return cloneElement(<DisableOverlayTrigger>{children}</DisableOverlayTrigger>, {
        ...errorProps,
      });
  }

  return <>{children}</>;
};
