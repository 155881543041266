import React, { useState, useEffect } from 'react';
import { CONTENT_URL } from '../../common/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import Tooltip from '@material-ui/core/Tooltip';
import SaveIcon from '@material-ui/icons/Save';
import { IconButton } from '@material-ui/core';
import { useMutation } from 'react-query';
import Services from '../../service-utils/services';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { getSessionData } from '../../service-utils';
import { ConformationBox } from '../../components/conformationBox';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import checkIcon from '../../assets/icon/check_icon.png';
const ThankYouPage = (props) => {
  const { goToNextStep, isLoadingUpdateLeadForm, leadFormData, refreshPage } = props;
  const [thankuouMsg, setThankuouMsg] = useState('Thank you for Submitting');
  const [isEditingButton, setIsEditingButton] = useState(false);
  const [isEditingThankuouMsg, setIsEditingThankuouMsg] = useState(false);
  const [conformation, setConformation] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryFormId = searchParams.get('formId');
  const { formId: routeFormId } = useParams();

  // Use whichever formId is available
  const formId = queryFormId || routeFormId;
  const { agencyId, advertiserId } = getSessionData();
  const handleEditToggleForThankYouMsg = () => {
    setIsEditingThankuouMsg(!isEditingButton);
  };
  const handleSaveForThankYouMsg = () => {
    setIsEditingThankuouMsg(false);
  };

  useEffect(() => {
    if (leadFormData !== null) {
      refreshPage();
      setThankuouMsg(leadFormData?.thankYouNote || 'Thank you for Submitting');
    }
  }, [leadFormData]);

  const handleSubmit = async (e, publish = false) => {
    e.preventDefault();
    let name = leadFormData?.name;
    let formId = leadFormData?.id;
    let formFieldsList = leadFormData?.formFieldsList;
    let description = leadFormData?.description;
    let title = leadFormData?.title;
    let button = leadFormData.buttonName;
    const query = queryString.stringify({ agencyId });
    const payload = {
      name: name,
      formFieldsList: formFieldsList,
      description: description,
      title: title,
      buttonName: button,
      published: publish,
      thankYouNote: thankuouMsg,
    };

    try {
      const response = await Services.leadFormUpdate(advertiserId, formId, query, payload);

      enqueueSnackbar('Successfully Submit.', { variant: 'success' });
      goToNextStep(leadFormData.id);
    } catch (err) {
      enqueueSnackbar(`${err}`, { variant: 'error' });
      handleMsgOnForm(err, enqueueSnackbar);
    }
  };
  return (
    <div className="landing-page  align-items-center p-4 bgColor">
      <Grid className="d-flex background p-3">
        <Grid
          item
          xs={4}
          className="d-flex flex-column justify-content-end align-items-center bg-white temp p-4"
        >
          <div className="">
            <div
              elevation={3}
              className="p-3 d-flex align-items-center justify-content-center logoImg"
              // style={{ height: "150px", maxWidth: "300px", margin: "auto" }}
            >
              <img
                className="rounded-sm p-2 logoImg"
                src={`${CONTENT_URL}/${leadFormData?.logoUrl}`}
                alt="logo"
                // style={{ width: "300px", height: "150px", objectFit: "contain" }}
              />
            </div>
            <div className=" justify-content-center mt-3 mb-4">
              {/* <CheckCircleIcon style={{ color: "green", fontSize: 40 }} /> */}
              <div className="sm d-flex justify-content-center align-items-center">
                <img src={checkIcon} style={{ width: '30px' }} alt="" />
              </div>

              <div className="d-flex justify-content-center mt-3">
                {isEditingThankuouMsg ? (
                  <>
                    <textarea
                      value={thankuouMsg}
                      onChange={(e) => setThankuouMsg(e.target.value)}
                      className="textarea"
                    />
                    <IconButton onClick={handleSaveForThankYouMsg} aria-label="save">
                      <SaveIcon className="iconsColor" />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Typography variant="h6">
                      {thankuouMsg}
                      <Tooltip title="Put Your thank you message" arrow>
                        <IconButton onClick={handleEditToggleForThankYouMsg} aria-label="edit">
                          <EditIcon className="iconsColor" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
          <ConformationBox
            isOpen={!!conformation}
            handleClose={() => setConformation()}
            handleSubmit={(e) => handleSubmit(e, true)}
            title="confirm ?"
            subtitle="Are you sure you want to Publish this lead form? Once publish it cannot be rollback."
            btnCloseLabel="Close"
            btnSubmitLabel="confirm"
          />
        </Grid>
      </Grid>
      <div className="flex mr-4" style={{ position: 'absolute', bottom: '45px', right: 0 }}>
        <Button
          className="btn btn-primary"
          disabled={leadFormData?.published}
          onClick={handleSubmit}
        >
          Save
        </Button>
        {leadFormData?.id && (
          <Button
            variant="contained"
            className="btn btn-primary ml-3"
            disabled={leadFormData?.published}
            onClick={(e) => {
              e.preventDefault();
              setConformation(true);
            }}
          >
            Update and Publish
          </Button>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
