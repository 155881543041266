import React from 'react';
import './inputElements.scss';
import Select from 'react-select';
import { InputLabel, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const NewSelect = (props) => {
  return (
    <div className={`position-relative ${props?.className}`}>
      <div className="label-container d-flex align-items-center">
        {props?.value && (
          <>
            <InputLabel className="mb-0 flotting-label">
              {props?.placeholder || `Select Date Range`}
              {props?.required && (
                <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  {' '}
                  *
                </span>
              )}
            </InputLabel>
            {props?.info ? (
              <div>
                <Tooltip
                  title={
                    <Typography varient="h6" className="TooltipFontSize">
                      {props?.info}
                    </Typography>
                  }
                  arrow
                >
                  <IconButton aria-label="info" size="small" className="info-icon">
                    <InfoOutlinedIcon className="infoIcon" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                {' '}
                <Tooltip title={props?.info} arrow>
                  <IconButton aria-label="info" size="small" className="info-icon">
                    {/* <InfoOutlinedIcon className="infoIcon" /> */}
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </div>
      <Select {...props} className="new-select" />
    </div>
  );
};

export default NewSelect;
