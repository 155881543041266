/* eslint-disable camelcase */
import { combineReducers } from 'redux';
import login from './login';
import headers from './headers';
import sideNavStatus from './side-navigation-reducer';

export default combineReducers({
  login,
  headers,
  sideNavStatus,
});
