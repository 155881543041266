import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../typography';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function OverlapLoader() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" className="loaderColor" />
        <span>
          {' '}
          <Typography className="loading font-weight-bold ml-1">Loading....</Typography>{' '}
        </span>
      </Backdrop>
    </div>
  );
}

export default OverlapLoader;
