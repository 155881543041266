import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  NewTextField,
  Paper,
  Grid,
  NewSelect,
  Button,
  Typography,
  NewDatePicker,
} from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { CAMPAIGN_STATUS, CONVERSION_STATUS } from '../../libs/constants';
import { dateFormatMoment } from '../../components/format-date-moment';
import { getMovementFromFormat } from '../../components/format-date-moment';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Checkbox, FormControlLabel } from '@material-ui/core';
export const CampaignCreate = () => {
  const { campaignId } = useParams();
  let { agencyId, agencyCurrency, advertiserId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/campaign', // Replace with your desired path
        },
      }),
    );
  }, [dispatch]);

  const pageLimit = 5000;
  const pageNumber = 0;

  const { data: allAdvertiser = [] } = useQuery(
    ['ALL_ADVERTISERS', pageLimit, pageNumber, agencyId],
    async () => {
      const payload = {
        pageSize: pageLimit,
        pageNum: pageNumber,
        enabled: true,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(payload));
      return response.data?.advertiserList;
    },
    { enabled: !!agencyId },
  );

  const { data: campaignData = {} } = useQuery(
    ['CAMPAIGN_DATA', campaignId, agencyId, advertiserId],
    async () => {
      const payload = {
        agencyId,

        currency: agencyCurrency,
      };
      const response = await Services.campaignGetDetails(
        campaignId,
        advertiserId,
        queryString.stringify(payload),
      );
      return response.data?.campaignDTO;
    },
    { enabled: !!campaignId && !!agencyId },
  );

  const { data: categoriesData = [] } = useQuery(
    ['IAB_DATA', agencyId],
    async () => {
      const payload = {
        agencyId,
      };
      const response = await Services.iabCategories(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId },
  );

  const { data: timezoneData = [] } = useQuery(
    ['TIMEZONE_DATA', agencyId],
    async () => {
      const payload = {
        agencyId,
      };
      const response = await Services.getMasterTimezone(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId },
  );

  const timezone_options = useMemo(() => {
    return timezoneData.map((item) => ({
      label: item?.label,
      value: item?.label,
    }));
  }, [timezoneData]);

  const iabCategories = useMemo(() => {
    return categoriesData?.map((item) => ({
      label: item.label,
      value: item.id,
    }));
  }, [categoriesData]);

  const { mutate: createCampaign, isLoading: isLoadingCreateCampaign } = useMutation(
    async (values) => {
      const {
        name,
        advertiser,
        status,
        startDate,
        endDate,
        iabCat,
        ipBasedFrequency,
        ipBasedFrequencyNoOfDays,
        userBasedFrequency,
        userBasedFrequencyNoOfDays,
        timezone,
        userTimeZone,
      } = values;

      const payload = {
        name: name,
        enabled: status.value,
        advertiserId: advertiser.value,
        advertiserName: advertiser.label,
        iabCategoryId: iabCat.value,
        ipBasedFrequency: parseInt(ipBasedFrequency),
        ipBasedFrequencyNoOfDays: parseInt(ipBasedFrequencyNoOfDays),
        userBasedFrequency: parseInt(userBasedFrequency),
        userBasedFrequencyNoOfDays: parseInt(userBasedFrequencyNoOfDays),
        campaignExchangeSeatIds: [],
        startDateTime: dateFormatMoment(startDate, 'DD/MM/yyyy HH:mm'),
        endDateTime: dateFormatMoment(endDate, 'DD/MM/yyyy HH:mm'),
        userTimeZone: userTimeZone,
        timezone: timezone.value,
      };
      const query = queryString.stringify({ agencyId });
      const response = !!campaignId
        ? await Services.campaignUpdate(advertiserId, campaignId, query, payload)
        : await Services.campaignCreate(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        if (!!campaignId) {
          enqueueSnackbar('Campaign updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('Campaign created successfully.', { variant: 'success' });
        }
        history.push(`/advertiser/campaign?advertiserId=${values.advertiser.value}`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: campaignData?.name || '',
      iabCat: campaignData?.iabCat
        ? { label: campaignData?.iabCat, value: campaignData?.iabCat }
        : {},
      advertiser: campaignData?.advertiserId
        ? { label: campaignData?.advertiserName, value: campaignData?.advertiserId }
        : {},
      status: campaignData?.status
        ? { label: campaignData?.status, value: campaignData?.status }
        : {},
      startDate: campaignData?.startDateTime
        ? getMovementFromFormat(campaignData?.startDateTime)
        : moment().add(20, 'minutes'),
      endDate: campaignData?.endDateTime
        ? getMovementFromFormat(campaignData?.endDateTime)
        : moment().add(15, 'days'),
      timezone: campaignData?.timezone
        ? {
            label: campaignData.timezone,
            value: campaignData.timezone,
          }
        : {},
      ipBasedFrequency: campaignData?.ipBasedFrequency,
      ipBasedFrequencyNoOfDays: campaignData?.ipBasedFrequencyNoOfDays,
      userBasedFrequency: campaignData?.userBasedFrequency,
      userBasedFrequencyNoOfDays: campaignData?.userBasedFrequencyNoOfDays,
      userTimeZone: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Campaign name is required.'),
    }),
    onSubmit: () => createCampaign(values),
  });

  useEffect(() => {
    const advertiser = allAdvertiser.filter((item) => item.id === campaignData?.advertiserId)[0];
    //const getStatus = CAMPAIGN_STATUS.filter(item =>(item.value === campaignData?.enabled))[0] || CAMPAIGN_STATUS[0];
    const selectCat = iabCategories.filter((item) => item.value === campaignData?.iabCategoryId)[0];
    campaignData?.iabCategoryId && setFieldValue('iabCat', selectCat);
    campaignData?.name && setFieldValue('name', campaignData?.name);
    campaignData?.timezone &&
      setFieldValue('timezone', {
        label: campaignData.timezone,
        value: campaignData.timezone,
      });
    campaignData?.ipBasedFrequency &&
      setFieldValue('ipBasedFrequency', campaignData?.ipBasedFrequency);
    campaignData?.ipBasedFrequencyNoOfDays &&
      setFieldValue('ipBasedFrequencyNoOfDays', campaignData?.ipBasedFrequencyNoOfDays);
    campaignData?.userBasedFrequency &&
      setFieldValue('userBasedFrequency', campaignData?.userBasedFrequency);
    campaignData?.userBasedFrequencyNoOfDays &&
      setFieldValue('userBasedFrequencyNoOfDays', campaignData?.userBasedFrequencyNoOfDays);
    campaignData?.advertiserId &&
      setFieldValue('advertiser', { label: advertiser?.name, value: advertiser?.id });
    campaignData?.name &&
      setFieldValue(
        'status',
        CAMPAIGN_STATUS.filter((item) => item.value === campaignData?.enabled)[0] ||
          CAMPAIGN_STATUS[0],
      );
    campaignData?.startDateTime &&
      setFieldValue('startDate', getMovementFromFormat(campaignData?.startDateTime).toDate());
    campaignData?.endDateTime &&
      setFieldValue('endDate', getMovementFromFormat(campaignData?.endDateTime).toDate());
    campaignData?.userTimeZone && setFieldValue('userTimeZone', campaignData.userTimeZone);
  }, [campaignData, allAdvertiser, setFieldValue, iabCategories]);
  const allAdvertisersOptions = useMemo(() => {
    return (
      orderBy(
        allAdvertiser?.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          disabled: item.status,
        })),
        [(advertiser) => advertiser.label.toLowerCase()],
        ['asc'],
      ) || []
    );
  }, [allAdvertiser]);
  const tncLoader = tncUpdated === 'true' && isLoadingCreateCampaign;

  console.log(values.userTimeZone);

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!campaignId ? 'Edit Campaign' : 'Create New Campaign'}
          </Typography>
        </div>

        {campaignData.expired && (
          <div className="d-flex justify-content-center textRed">
            <Typography variant="h6">This campaign has been expired.</Typography>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                required
                info="Give suitable name for your campaign e.g Diwali Campaign, Winter Season Campaign"
                context="this is campaign name"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Campaign Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <NewSelect
                required
                info="Choose the category from the list that describes your ad."
                options={orderBy(iabCategories, 'label')}
                value={values.iabCat}
                onChange={(obj) => setFieldValue('iabCat', obj)}
                placeholder={`IAB Categories`}
                className="py-2"
              />
              {serverErrors.iabCat && <p className="text-danger mt-n2">{serverErrors.iabCat}</p>}
            </Grid>

            <Grid item xs={6} sm={3} className="py-0">
              <NewDatePicker
                info="Set valid start date and time of your campaign"
                placeholder={`Start Date`}
                isSingleDate={true}
                required
                initialValue={values.startDate}
                timePicker={true}
                onCallback={(date) => setFieldValue('startDate', date)}
                key={values.startDate}
                // minDate={moment()}
                // maxDate={values.endDate}
                minDate={campaignData.expired === true || campaignData.name ? null : moment()}
                isDisabled={!campaignId ? false : !campaignData?.dateChangeAllowed}
              />
              {serverErrors.startDateTime && (
                <p className="text-danger mt-n2">{serverErrors.startDateTime}</p>
              )}
            </Grid>
            <Grid item xs={6} sm={3} className="py-0">
              <NewDatePicker
                info="Set valid end date and time of your campaign"
                placeholder={`End Date`}
                isSingleDate={true}
                required
                initialValue={values.endDate}
                timePicker={true}
                // maxDate={values.endDate}
                minDate={campaignData.expired === true ? null : moment()}
                onCallback={(date) => setFieldValue('endDate', date)}
                key={values.endDate}
                isDisabled={!campaignId ? false : !campaignData?.endDateChangeAllowed}
              />
              {serverErrors.endDateTime && (
                <p className="text-danger mt-n2">{serverErrors.endDateTime}</p>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <FormControlLabel
                className="pt-4"
                control={
                  <Checkbox
                    checked={values.userTimeZone}
                    onChange={(event, val) => {
                      setFieldValue('userTimeZone', val);
                    }}
                    name="checkedB"
                    color="primary"
                    className="m-0"
                    disabled={campaignId}
                  />
                }
                label="User TimeZone"
              />
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <NewSelect
                required
                info="Enable the status to start campaign. Disable to pause it"
                options={CAMPAIGN_STATUS}
                value={values.status}
                onChange={(obj) => {
                  setFieldValue('status', obj);
                }}
                placeholder={`Status`}
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid>
            {!values.userTimeZone && (
              <Grid item xs={12} sm={6} className="py-0">
                <NewSelect
                  info="Select your timezone based on your region"
                  options={timezone_options}
                  value={values.timezone}
                  required
                  onChange={(obj) => {
                    setFieldValue('timezone', obj);
                  }}
                  placeholder={`Timezone`}
                  className="py-2"
                  isDisabled={!campaignId ? false : !campaignData?.dateChangeAllowed}
                />
                {serverErrors.timezone && (
                  <p className="text-danger mt-n2">{serverErrors.timezone}</p>
                )}
              </Grid>
            )}
            <Grid item xs={12} className="py-0 d-flex sentance-with-input">
              <Typography variant="paraText" classes="sentance">
                FREQUENCY CAPPING
              </Typography>
            </Grid>
            <Grid item xs={12} className="py-0 d-flex sentance-with-input">
              <Typography variant="paraText" classes="sentance">
                Show per IP no more than
              </Typography>
              <NewTextField
                info="Maximum impressions per ip"
                className="mx-1"
                error={Boolean(touched.ipBasedFrequency && errors.ipBasedFrequency)}
                fullWidth
                helperText={touched.ipBasedFrequency && errors.ipBasedFrequency}
                label="IP Frequecy"
                margin="normal"
                name="ipBasedFrequency"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('ipBasedFrequency', event.target.value)}
                value={values.ipBasedFrequency}
                variant="outlined"
              />
              <Typography variant="paraText" classes="sentance">
                {' '}
                time(s){' '}
              </Typography>
              <NewTextField
                className="mx-1"
                info="Duration for the allowed impressions per ip "
                error={Boolean(touched.ipBasedFrequencyNoOfDays && errors.ipBasedFrequencyNoOfDays)}
                fullWidth
                helperText={touched.ipBasedFrequencyNoOfDays && errors.ipBasedFrequencyNoOfDays}
                label="No of Days"
                margin="normal"
                name="ipBasedFrequencyNoOfDays"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('ipBasedFrequencyNoOfDays', event.target.value)}
                value={values.ipBasedFrequencyNoOfDays}
                variant="outlined"
              />
              <Typography variant="paraText" classes="sentance">
                per day(s).
              </Typography>
            </Grid>

            <Grid item xs={12} className="py-0 d-flex sentance-with-input">
              <Typography variant="paraText" classes="sentance">
                Show per Device no more than
              </Typography>
              <NewTextField
                info="Maximum impressions per device"
                className="mx-1"
                error={Boolean(touched.userBasedFrequency && errors.userBasedFrequency)}
                fullWidth
                helperText={touched.userBasedFrequency && errors.userBasedFrequency}
                label="User Frequency"
                margin="normal"
                name="userBasedFrequency"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('userBasedFrequency', event.target.value)}
                value={values.userBasedFrequency}
                variant="outlined"
              />
              <Typography variant="paraText" classes="sentance">
                {' '}
                time(s){' '}
              </Typography>
              <NewTextField
                info="Duration for the allowed impressions per device"
                className="mx-1"
                error={Boolean(
                  touched.userBasedFrequencyNoOfDays && errors.userBasedFrequencyNoOfDays,
                )}
                fullWidth
                helperText={touched.userBasedFrequencyNoOfDays && errors.userBasedFrequencyNoOfDays}
                label="No of Days"
                margin="normal"
                name="userBasedFrequencyNoOfDays"
                onBlur={handleBlur}
                onChange={(event) =>
                  setFieldValue('userBasedFrequencyNoOfDays', event.target.value)
                }
                value={values.userBasedFrequencyNoOfDays}
                variant="outlined"
              />
              <Typography variant="paraText" classes="sentance">
                per day(s).
              </Typography>
            </Grid>
          </Grid>
          <div className="mt-4 d-flex justify-content-end ">
            <Button
              variant="contained"
              class="btn btn-secondary d-flex"
              onClick={() => history.push(`/advertiser/campaign`)}
            >
              Cancel
            </Button>
            <Button
              disabled={campaignData.expired}
              variant="contained"
              type="submit"
              className="btn btn-primary d-flex ml-4"
            >
              {!!campaignId
                ? tncLoader
                  ? 'Updating'
                  : 'Update'
                : tncLoader
                ? 'Creating'
                : 'Create'}
            </Button>
            {tncLoader && <OverlapLoader />}
          </div>
        </form>
      </Paper>
    </div>
  );
};
