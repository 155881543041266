import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import { getSessionData } from '../../service-utils';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Table } from '../../components';
import { Grid, Loader, Paper, Typography } from '../../common';
import { useHistory } from 'react-router-dom';
import { dateFormatMoment } from '../../components/format-date-moment';
import { getCurrencySymbol, numberFormatter } from '../../common/utils';
import { Card, CardContent, Tooltip } from '@material-ui/core';
import spendIcon from '../../assets/icon/spend.png';
import PeopleIcon from '@material-ui/icons/People';
import totalConversion from '../../assets/icon/totalConversion.png';
const ConversionReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { agencyId, agencyCurrency } = getSessionData();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { conversionCode, conversionName } = queryParams;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const {
    data = [],
    refetch: refresh,
    isLoading: isLoadingOrderDetails,
  } = useQuery(
    ['CONVERSION_COUNT', agencyId, conversionCode, pageNumber, pageLimit],
    async () => {
      const queryStringParams = { conversionCode, pageNum: pageNumber - 1, pageSize: pageLimit };
      const response = await Services.getConversionCount(
        agencyId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    {
      enabled: !!agencyId && !!conversionCode,
    },
  );

  const tableData = useMemo(() => {
    return data.conversionCount || [];
  }, [data]);

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/conversion',
        },
      }),
    );
  }, [dispatch]);

  const handleButtonClick = () => {
    history.push('/conversion-create');
  };

  const getTableHeader = () => {
    return [
      {
        dataField: 'dateTime',
        text: 'Conversion Date',
        formatter: (col) => <div>{dateFormatMoment(col)}</div>,
      },
      {
        dataField: 'conversionId',
        text: 'Conversion Id',
      },
      {
        dataField: 'campaignName',
        text: 'Campaign Name',
      },
      {
        dataField: 'creativeName',
        text: 'Creative Name',
      },
      {
        dataField: 'conversionValue',
        text: 'Conversion Value',
      },
    ];
  };
  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };
  const SummaryBox = (props) => {
    const { title = '', icon, image, value = '', valuesData, titles, tooltipValue } = props;
    return (
      <Card className="d-flex p-2">
        <div className="d-flex w-100 align-items-center">
          <div className="w-15 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>
          <div className="w-75">
            <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
              <CardContent className="p-2 mr-5">
                <div className="filters d-flex justify-content-between">
                  <Typography className=" font30 summarytitleColor">{title || ''}</Typography>
                  <Typography className="mr-2 h4 font30">{value || ''}</Typography>
                </div>
              </CardContent>
            </Tooltip>
          </div>
        </div>
      </Card>
    );
  };
  return (
    <div>
      <div className="w-75">
        <div class="col inline-left-div mb-3">
          {' '}
          <h3>Conversion Name :</h3>
          <span class="campaign-name">{conversionName}</span>
        </div>
        <div class="col inline-left-div">
          <h3>Conversion Code:</h3>
          <span class="campaign-name">{conversionCode}</span>
        </div>
      </div>{' '}
      <div className="summary-box mt-0 mb-3">
        <Grid container spacing={3}>
          <Grid item xs={4} className="d-grid ">
            <SummaryBox
              title="Conversions"
              image={totalConversion}
              // icon={<RemoveRedEyeIcon className="font50 primary-color" />}
              value={`${numberFormatter(data?.totalConversions || 0)}`}
              tooltipValue={`Conversions Count: ${data?.totalConversions || 0}`}
            />
          </Grid>
          <Grid item xs={4} className="d-grid">
            <SummaryBox
              title="Users"
              icon={<PeopleIcon className="font50 primary-color" />}
              value={` ${numberFormatter(data?.totalUsers || 0)}`}
              tooltipValue={`Number of Users: ${data?.totalUsers || 0}`}
            />
          </Grid>

          <Grid item xs={4} className="d-grid ">
            <SummaryBox
              title="Total Value"
              image={spendIcon}
              value={`${getCurrencySymbol(agencyCurrency)} ${numberFormatter(
                data?.totalPrice || 0,
              )}`}
              tooltipValue={`Conversion Value : ${data?.totalPrice || 0}`}
            />
          </Grid>
        </Grid>
      </div>
      {isLoadingOrderDetails ? (
        <Loader />
      ) : (
        <>
          <Table
            keyField="id"
            tableData={tableData}
            tableHeader={getTableHeader()}
            isRemote={true}
            onPageChangeFun={handleTableChange}
            totalSize={data?.totalElements}
            activePage={pageNumber}
            sizePerPage={pageLimit}
            wrapperClasses="scroll-visible"
            defaultSorted={[
              {
                dataField: 'firstName',
                order: 'asc',
              },
            ]}
            // onButtonClick={handleButtonClick}
            // buttonLabel="Click here to new Conversion"
            // additionalText="To get started, set up conversion tracking to count the number of conversions."
          />
        </>
      )}
    </div>
  );
};

export default ConversionReport;
