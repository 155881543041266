import React, { useState, useEffect } from 'react';
import { Chart } from '../../components';
import { Grid } from '../../common';
import { dateFormatMoment, getEpochDate } from '../../components/format-date-moment';
import { orderBy } from 'lodash';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import queryString from 'query-string';
import { useQuery } from 'react-query';
//import { CACHE_TIME, STALE_TIME } from './../../common/constants';
import { backgroundColor, borderColor, CHART_COLOR, getCurrencySymbol } from '../../common/utils';
import { externalTooltipHandler } from '../../components/chart/index';
export const ImpressionTrendChart = (props) => {
  const { selectedDate } = props;
  const { agencyId } = getSessionData();
  const [currency, setCurrency] = useState();
  let interval = 'DAILY';
  const { data } = useQuery(
    ['IMPRESSION_TREND_CHART', agencyId, interval, selectedDate], // Include interval in the query key
    async () => {
      const payload = {
        agencyId,
        interval,
        startDate: getEpochDate(selectedDate[0]),
        endDate: getEpochDate(selectedDate[1]),
      }; // Include interval in the payload
      const response = await Services.getImpressionTrendLine(queryString.stringify(payload));
      return response.data?.graphDataList;
    },
    {
      enabled: !!agencyId,
    },
  );

  const sortedData = orderBy(data, ['label'], ['asc']) || [];

  useEffect(() => {
    // Find the first item with a valid currency and set it
    const firstItemWithCurrency = sortedData.find((item) => item.totalCost.currency);
    if (firstItemWithCurrency) {
      setCurrency(firstItemWithCurrency.totalCost.currency);
    }
  }, [sortedData]);

  const Data = {
    currency: currency,
    labels: sortedData?.map((date) => {
      const selectedDate = parseInt(date.label);
      return dateFormatMoment(selectedDate).split(' ', 2)[0]; // Assuming dateFormatMoment returns 'YYYY-MM-DD'
    }),
    datasets: [
      {
        fill: false,
        label: 'Impressions',
        data: sortedData.map((item) => item.value),
        backgroundColor: CHART_COLOR.IMPRESSION,
        borderColor: CHART_COLOR.IMPRESSION,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y',
      },
      {
        fill: false,
        label: 'Spend',
        data: sortedData.map((item) => item.totalCost.amount),
        backgroundColor: CHART_COLOR.SPEND,
        borderColor: CHART_COLOR.SPEND,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y2',
      },
      {
        fill: false,
        label: 'Click Count',
        data: sortedData.map((item) => item.clickCount),
        backgroundColor: CHART_COLOR.CLICK_COUNT,
        borderColor: CHART_COLOR.CLICK_COUNT,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          id: 'y',
          position: 'left',
          label: 'Number Of Impressions',
          color: CHART_COLOR.IMPRESSION,
        },
        {
          id: 'y1',
          position: 'left',
          label: 'Number Of Clicks',
          color: CHART_COLOR.CLICK_COUNT,
          grid: {
            drawOnChartArea: false,
          },
        },
        {
          id: 'y2',
          position: 'right',
          label: ` Spend ( ${getCurrencySymbol(currency)} )`,
          color: CHART_COLOR.SPEND,
          grid: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        callbacks: {
          label: (tooltipItem) => {
            const {
              dataset: { label = '', data = [] },
              dataIndex,
            } = tooltipItem || {};

            let tooltipText = `${label} : ${data[dataIndex]}`;

            if (label === 'Spend') {
              tooltipText = `${label} : ${getCurrencySymbol(currency)} ${data[dataIndex]}`;
            }

            return tooltipText;
          },
        },
      },
    },
  };

  return <Chart chartData={Data} options={options} height="90px" />;
};
