import queryString from 'query-string';
import { useQuery, useMutation } from 'react-query';
import Services from './../services';
import { CACHE_TIME, STALE_TIME } from './../../common/constants';
import { getEpochDate } from './../../components/format-date-moment';

export const AgencyGetBalance = (agencyId, userAgencyId, queryParam) => {
  return useQuery(
    ['AGENCY_BALANCE', agencyId, userAgencyId],
    async () => {
      const queryStringParams = { ...queryParam };
      const response = await Services.agencyGetBalance(
        agencyId,
        userAgencyId,
        queryString.stringify(queryStringParams),
      );
      return response.data?.agencyAccountDto;
    },
    {
      enabled: !!agencyId && !!userAgencyId,
      staleTime: STALE_TIME.FIVE_MIN,
      cacheTime: CACHE_TIME.FIVE_MIN,
    },
  );
};

export const AgencyCreditBalance = (agencyId, userAgencyId, data, callback) => {
  const { onError, onSuccess } = callback;
  return useMutation(
    async () => {
      const payload = {
        agencyId: parseInt(userAgencyId),
        amount: { amount: parseInt(data.addAmount) || 0, currency: data.currency },
        notes: data.note,
        txnDate: getEpochDate(),
        txnRef: data.ref,
        totalAmount: {
          amount: parseInt(data.totalAmount),
          currency: data.currency,
        },
        gst: parseInt(data.gstAmount),
        transactionId: data.transactionId,
        paymentMethodType: data.paymentMethodType,
        bankid: data.bankid,
        pgName: 'BANK_TRANSFER',
        gatewayEnum: 'BANK_TRANSFER',
        isGenerateInvoice: data?.isGenerateInvoice?.value,
      };

      const response = await Services.agencyCreditBalance(agencyId, userAgencyId, payload);
      return response.data;
    },
    {
      onError: (err) => {
        onError(err);
      },
      onSuccess: () => {
        onSuccess();
      },
    },
  );
};

export const AgencyLedgerDetails = (
  agencyId,
  userAgencyId,
  pageNumber,
  pageLimit,
  agencyCurrency,
) => {
  return useQuery(
    ['AGENCY_LEDGER_DETAILS', agencyId, userAgencyId, pageNumber, pageLimit, agencyCurrency],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyId,
        currency: agencyCurrency,
      };
      const response = await Services.agencyLedgerDetails(
        userAgencyId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    {
      enabled: !!agencyId && !!userAgencyId,
      staleTime: STALE_TIME.FIVE_MIN,
      cacheTime: CACHE_TIME.FIVE_MIN,
    },
  );
};
