/* eslint-disable no-case-declarations */
const defaultState = {
  header: null,
  caption: null,
  back: null,
  child: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'SET_HEADER':
      const {
        header = null,
        caption = null,
        back = null,
        breadcrumb = null,
        helpId = null,
        tourId = null,
        infoDetail = null,
        child = [],
        breadcrumbDropdown = null,
        isFullWidth = false,
      } = payload;
      return {
        ...state,
        header,
        caption,
        back,
        helpId,
        tourId,
        infoDetail,
        breadcrumb,
        child,
        breadcrumbDropdown,
        isFullWidth,
      };
    default:
      return { ...state };
  }
};
