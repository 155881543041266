import React from 'react';
import { NewSelect, Typography } from '../../common';
import { getTruncateText } from '../../common/utils';
import Tooltip from '@material-ui/core/Tooltip';

const SelectedItems = ({ items, onRemove }) => (
  <div className="selected-items justify-content-center align-items-center">
    {items.map((item) => (
      <Tooltip title={<Typography className="TooltipFontSize">{item.label}</Typography>} arrow>
        <span key={item.value} className="selected-item " title={item.label}>
          {getTruncateText(item.label, 15)}
          <button onClick={() => onRemove(item)}>×</button>
        </span>
      </Tooltip>
    ))}
  </div>
);

const SelectWithItems = ({
  info,
  options,
  placeholder,
  selectedItems,
  onSelectChange,
  onRemove,
}) => (
  <div className="select-container">
    <NewSelect
      info={info}
      options={options}
      value={[]} // Display currently selected items
      onChange={onSelectChange} // Directly pass the onChange handler
      placeholder={placeholder}
      isMulti
      className="mt-n3 ml-3"
      disabled={false}
    />
    <SelectedItems items={selectedItems} onRemove={onRemove} />
  </div>
);

export default SelectWithItems;
