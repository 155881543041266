import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';

const CreateIcon = (props) => {
  const { handleAdd, label } = props;

  return (
    <div>
      <Tooltip title={<Typography className="TooltipFontSize">{label}</Typography>} arrow>
        <div
          onClick={handleAdd}
          aria-label={label}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '38px', // Set width explicitly
            height: '38px', // Set height explicitly
            borderRadius: '50%', // Circular button
            backgroundColor: '#fff', // White background
            cursor: 'pointer',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for depth
          }}
        >
          <AddIcon className="primary-color" /> {/* Orange icon */}
        </div>
      </Tooltip>
    </div>
  );
};

export default CreateIcon;
