import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { NewDateRangePicker, NewSelect } from './../../common';
import { CampaignImpressionChartByAdv } from './CampaignImpressionChartByAdv';
import { ImpressionTrendChartByAdv } from './ImpressionTrendChartByAdv';
import { StateImpressionChartByAdv } from './StateImpressionChartByAdvertiser';
import { DeviceTypeImpressionChartByAdv } from './DeviceTypeImpressionChartByAdv';
import CampaignsByCategoryChartByAdv from './CampaignsByCategoryChartAdv';
import { ImpressionByCategoryByAdv } from './ImpressionByCategoryAdv';
import moment from 'moment';
import { getSessionData } from '../../service-utils';
import Services from '../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import { Summary } from './summary';
const chartFactor = [
  { label: 'Impressions', value: 'Impressions' },
  { label: 'Click', value: 'Click' },
  { label: 'Spend', value: 'Spend' },
];

const AdvertiserDashboard = () => {
  const [chartType, setChartType] = useState(chartFactor[0]);
  const dispatch = useDispatch();
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(1, 'month').toDate(),
    moment().toDate(),
  ]);
  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Advertiser Dashboard
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const { data: campaignAnalyticsData } = useQuery(
    ['CAMPAIGN_ANALYTICS_DATA', agencyId, advertiserId],
    async () => {
      const queryStringParams = {
        advertiserId,
      };
      const response = await Services.getCampaignAnalyticsData(
        agencyId,
        queryString.stringify(queryStringParams),
      );
      return response?.data?.campaignAnalyticsDTO;
    },
    { enabled: !!agencyId && isEmpty(advertiserId) },
  );

  return (
    <>
      <div className="dashboard-page">
        <Summary campaignAnalyticsData={campaignAnalyticsData} />
        <Grid container spacing={3} className="mt-2">
          <Grid item xs={12} md={12} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container impressions-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    Impressions in last 30 days
                  </Typography>
                  {/* <NewSelect
                    required
                    options={chartFactorForTrendline}
                    value={chartTypeForTrendLine}
                    onChange={(obj) => {
                      setChartTypeForTrendLine(obj);
                    }}
                    placeholder={`Frequency`}
                    className="mt-n3"
                  /> */}
                  <div className="mt-n3">
                    <NewDateRangePicker
                      info="Select date range from where you want to see the report"
                      initialRange={selectedDate}
                      timePicker={true}
                      selected={selectedDate}
                      maxDate={selectedDate[1]}
                      onCallback={(start, end) => setSelectedDate([start, end])}
                    />
                  </div>
                </div>
                <ImpressionTrendChartByAdv selectedDate={selectedDate} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container geographical-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Geographical Distribution of Impressions
                </Typography>
                <StateImpressionChartByAdv />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container campaigns-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Impressions by Campaigns
                </Typography>
                <CampaignImpressionChartByAdv />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container platform-chart">
              <div className="p-3">
                <Typography component="h5" variant="h6" className="pb-2">
                  Platform viz Distribution of Impressions
                </Typography>
                <DeviceTypeImpressionChartByAdv />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container category-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    Campaign's Category
                  </Typography>
                  <NewSelect
                    required
                    options={chartFactor}
                    value={chartType}
                    onChange={(obj) => setChartType(obj)}
                    placeholder={`Type`}
                    className="mt-n3 "
                  />
                </div>
                <CampaignsByCategoryChartByAdv chartType={chartType.value} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="d-grid ">
            <Paper className="p-0 mn-h-200 chart-container  category-ctr-chart">
              <div className="p-3">
                <div className="filters d-flex justify-content-between">
                  <Typography component="h5" variant="h6" className="pb-2">
                    CTR by Category
                  </Typography>
                </div>
                <ImpressionByCategoryByAdv />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AdvertiserDashboard;
