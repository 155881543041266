import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import './inputElements.scss'; // Adjust path to your stylesheet if needed
import { Typography } from '@material-ui/core';
const NewTextField = ({ info, ...props }) => {
  return (
    <TextField
      {...props}
      className={`text-field ${props?.className}`}
      InputProps={{
        endAdornment: info ? (
          <InputAdornment position="end">
            <Tooltip title={<Typography className="TooltipFontSize">{info}</Typography>} arrow>
              <IconButton aria-label="info">
                <InfoOutlinedIcon className="infoIcon" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};
export default NewTextField;
