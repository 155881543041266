import React from 'react';
import { useParams } from 'react-router-dom'; // Added missing import
import { UserRegistration } from './User-registration';
import logo from '../../assets/img/adzy.png';
import { Grid, Loader } from '../../common'; // Assuming this is a custom component
import { useQuery } from 'react-query'; // Removed unused `useMutation`
import Services from '../../service-utils/services';
import { useSnackbar } from 'notistack';
import { AgencyRegistration } from './agency-registration';

const Registration = () => {
  const { registerCode } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useQuery(
    ['INVITE_DATA', registerCode],
    async () => {
      const response = await Services.getInvite(registerCode);
      return response.data?.userDTO;
    },
    {
      enabled: !!registerCode,
      onError: (error) => {
        const errorMsg = error?.response?.data?.reasonDesc || 'Error in Registration.';
        // enqueueSnackbar(errorMsg, { variant: 'error' });
        // setErrorText(errorMsg);
        enqueueSnackbar(errorMsg, { variant: 'error' });
        // history.push('/login');
      },
    },
  );

  return (
    <div className="login-page">
      <Grid container className="full-height-grid">
        <Grid
          item
          xs={12}
          md={7}
          className="d-flex justify-content-center align-items-center bg-dark1 slider-container"
        >
          <img src={logo} alt="logo" className="img-fluid" />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          className="d-flex justify-content-center align-items-center h-100 login-form-container p-4"
        >
          <div className="text-center w-100">
            {isLoading ? (
              <Loader />
            ) : data?.agencyId != null ? (
              <UserRegistration data={data} registerCode={registerCode} />
            ) : (
              <AgencyRegistration data={data} registerCode={registerCode} />
            )}
          </div>
        </Grid>
      </Grid>

      {/* Uncomment the footer if needed */}
      {/* <Footer /> */}
    </div>
  );
};

export default Registration;
