import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getSessionData } from '../../service-utils';
import { useMutation } from 'react-query';
import queryString from 'query-string';
import Services from '../../service-utils/services';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const TncConsentPopup = () => {
  const [show, setShow] = useState(true);
  const { agencyId, userId } = getSessionData();
  const history = useHistory();

  const { mutate: tncDateUpdated, isLoading } = useMutation(
    async () => {
      const currentDate = Date.now();

      const payload = {
        tncDate: currentDate,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.updateTncDate(userId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        console.error('Error updating TNC date:', err);
      },
      onSuccess: () => {
        Cookies.set('tncUpdated', 'true');
        setShow(false);
        window.location.reload();
      },
    },
  );

  const handleContinueClick = () => {
    tncDateUpdated();
  };

  const handleCancelClick = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleCancelClick} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Terms and Conditions Update</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          We have recently updated our{' '}
          <span>
            <a
              className="footer-link text-primary"
              href="https://www.adzylytics.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
          </span>
          . Please review and accept the new terms to continue using our services.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleContinueClick} disabled={isLoading}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TncConsentPopup;
