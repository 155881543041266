import React from "react";
import { TextField} from '../../common';

const SearchInput = ({...rest}) =>{
    return (
        <>
         <TextField id="outlined-basic" label="Search..." variant="outlined" className="input" {...rest}/>
        </>
    )
}

export default SearchInput