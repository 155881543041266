import moment from 'moment';

export const dateFormatMoment = (date = new Date(), format = 'DD/MM/yyyy HH:mm') => {
  return moment(date).format(format);
};

export const timeFormatMoment = (time = new Date(), format = 'hh:mm:ss A') => {
  return moment(time).format(format);
};

export const getMovementFromFormat = (datestr) => {
  return moment(datestr, 'DD/MM/YYYY HH:mm', true);
};
//dd/MM/yyyy HH:mm

export const getEpochDate = (date) => {
  return moment(date).unix() * 1000;
};

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  );
};
