export const setSideNavigationMenuStatus = (data) => (dispatch) =>
  dispatch({
    type: 'SET_SIDE_NAV_MENU_STATUS',
    payload: data,
  });

export const setSideNavigationOpen = (data) => (dispatch) =>
  dispatch({
    type: 'SET_SIDE_NAV_OPEN',
    payload: data,
  });
