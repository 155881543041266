import React from 'react';
import { Grid, Card, Tooltip, IconButton } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import getSessionData from '../../service-utils/session-util';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import spendIcon from '../../assets/icon/spend.png';
import { getCurrencySymbol } from './../../common/utils';
import { numberFormatter } from './../../common/utils';
import { DashboardOverviewSummaryForAdv } from '../../service-utils/useApis/useAdvertiserDashbordApi';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import GroupIcon from '@material-ui/icons/Group';
import WinRateChart from '../../components/WinRateChart';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
const SummaryBox = (props) => {
  const {
    title = '',
    icon,
    image,
    value = 0,
    valuesData = '',
    titles = '',
    tooltipValue = '',
    infoIconData = '',
  } = props;

  return (
    <Card className="d-flex p-2 summary-box">
      <div
        className="d-flex w-100 align-items-center"
        style={{ height: '112px', position: 'relative' }}
      >
        <div className="w-25 summary-icon">{icon ? icon : <img alt="image" src={image} />}</div>

        <div className="w-75">
          <Tooltip title={<Typography className="TooltipFontSize">{tooltipValue}</Typography>}>
            <CardContent className="p-2 mr-5">
              <div className="filters d-flex justify-content-between mb-2">
                <Typography className="mt-1 font20 summarytitleColor">{title || ''}</Typography>
                <Typography className="mr-2 h4 font30">{value || ''}</Typography>
              </div>

              <div className="dotted-line font20 summaryBoxBorder"></div>
              <div className="filters d-flex justify-content-between mt-2">
                <Typography className="h4 mt-1 font30">{valuesData || ''}</Typography>
                <Typography className="mt-2 font20 summarytitleColor">{titles || ''}</Typography>
              </div>
            </CardContent>
          </Tooltip>
        </div>

        <Tooltip title={<Typography className="TooltipFontSize">{infoIconData}</Typography>} arrow>
          <IconButton
            aria-label="info"
            className="info-icon"
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
          >
            <InfoOutlinedIcon className="infoIcon" />
          </IconButton>
        </Tooltip>
      </div>
    </Card>
  );
};
//Total impression, CTR,	Budget,	total spent
export const Summary = ({ campaignAnalyticsData }) => {
  const { advertiserId } = useAdvertiser();

  const { agencyId, agencyCurrency } = getSessionData();
  const { data = {} } = DashboardOverviewSummaryForAdv(agencyId, advertiserId, agencyCurrency);
  // function kFormatter(num) {
  //     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  // }

  let eCPC = data.click ? data.totalSpent?.amount / data.click : 0;
  let eCPM = data.totalImpressions ? (data.totalSpent?.amount * 1000) / data.totalImpressions : 0;
  return (
    <div className="dashboard-container1">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SummaryBox
                title="Impressions"
                icon={<RemoveRedEyeIcon className="font50 primary-color" />}
                value={numberFormatter(data?.totalImpressions) || 0}
                valuesData={
                  `${getCurrencySymbol(data?.totalSpent?.currency)} ${numberFormatter(eCPM)}` || 0
                }
                titles="eCPM"
                tooltipValue={`Impressions: ${data?.totalImpressions || 0}`}
                infoIconData={`Impressions in digital advertising refer to the number of times an ad is displayed on a user's screen. Each time the ad appears, it counts as one impression, regardless of whether the user interacts with it or not`}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SummaryBox
                title="Clicks"
                icon={<TouchAppIcon className="font50 primary-color" />}
                value={` ${numberFormatter(data?.click || 0)}`}
                valuesData={`${data?.ctr !== undefined ? `${data.ctr}%` : '0%'}`}
                titles="CTR"
                tooltipValue={`Clicks: ${data?.click || 0}`}
                infoIconData={`Clicks in digital advertising refer to the number of times users interact with an ad by clicking on it. This typically redirects them to the advertiser's website, app, or landing page.`}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SummaryBox
                title="Total Spent"
                image={spendIcon}
                value={
                  `${getCurrencySymbol(data?.totalSpent?.currency)} ${numberFormatter(
                    data?.totalSpent?.amount,
                  )}` || 0
                }
                valuesData={
                  `${getCurrencySymbol(data?.totalSpent?.currency)} ${numberFormatter(eCPC)}` || 0
                }
                titles="eCPC"
                tooltipValue={
                  `Total Spent: ${getCurrencySymbol(data?.totalSpent?.currency)} ${
                    data?.totalSpent?.amount
                  }` || 0
                }
                infoIconData={`Total Spent in digital advertising refers to the total amount of money an advertiser has spent on a campaign or specific ad over a given period.`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SummaryBox
                title="Unique reach"
                icon={<GroupIcon className="font50 primary-color" />}
                value={`${numberFormatter(campaignAnalyticsData?.uniqueReach)}`}
                tooltipValue={`Unique reach: ${campaignAnalyticsData?.uniqueReach || 0}`}
                infoIconData={`Unique Reach refers to the number of individual users (or unique devices) who have seen an ad at least once during a campaign, without counting repeated views by the same user. It helps advertisers measure the true size of their audience.`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="">
            <WinRateChart
              campaignAnalyticsData={campaignAnalyticsData}
              info={`Win Rate is the percentage of times your bids win in ad auctions compared to the total number of bids you place.`}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
