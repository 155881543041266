import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewSelect, TextField } from '../common';
import history from '../history';
import Services from '../service-utils/services';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { AGENCY_TYPE, AGENCY_CURRENCY } from '../libs/constants';
import { handleMsgOnForm } from '../common/utils';
import { isEmpty } from 'lodash';
import checkIcon from '../assets/icon/check_icon.png';
import '../views/register.scss';
import OverlapLoader from '../common/loader/OverlapLoader';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export const RegistrationForm = ({ redirectFrom }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [successStatus, setSuccessStatus] = useState(false);
  const { mutate: createAgency, isLoading: isLoadingCreateAgency } = useMutation(
    async (values) => {
      const {
        agencyName,
        agencyType,
        contactNo,
        contactEmail,
        contactName,
        gstNumber,
        country,
        message,
        agencyCurrency,
      } = values;
      const payload = {
        agencyName,
        agencyType: agencyType?.value,
        contactNo,
        contactName,
        contactEmail,
        gstNumber,
        country,
        message,
        status: 0,
        currency: agencyCurrency?.value || 'USD',
      };
      const response = await Services.agencyCreate(payload);
      return response.data;
    },
    {
      onError: (err) => handleMsgOnForm(err, enqueueSnackbar, setServerErrors),
      onSuccess: () => {
        enqueueSnackbar('Agency created successfully.', { variant: 'success' });
        setSuccessStatus(true);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      agencyName: '',
      agencyCurrency: AGENCY_CURRENCY[0],
    },
    validationSchema: Yup.object({
      agencyName: Yup.string()
        .min(2, 'Must be more than 1 character')
        .max(255, 'Must be less than 255 characters')
        .required('Company name is required.'),
    }),
    onSubmit: () => createAgency(values),
  });

  const handleCancel = () => {
    redirectFrom === 'agency' ? history.push(`/agency`) : history.push(`/login`);
  };

  return (
    <div className="">
      <div className="pt-">
        {!successStatus ? (
          <>
            <div className="d-flex justify-content-center">
              <Typography variant="h6">Create your account</Typography>
            </div>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    helperText={touched.agencyName && errors.agencyName}
                    error={Boolean(touched.agencyName && errors.agencyName)}
                    label="Company Name"
                    margin="normal"
                    name="agencyName"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('agencyName', event.target.value)}
                    value={values.agencyName}
                    variant="outlined"
                  />
                  {serverErrors.agencyName && (
                    <p className="text-danger mt-n2">{serverErrors.agencyName}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className="mt-">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="company-type-label">Company Type</InputLabel>
                    <Select
                      labelId="company-type-label"
                      id="company-type-select"
                      value={values.agencyType}
                      label="Company Type"
                      onChange={(event) => setFieldValue('agencyType', event.target.value)}
                      required
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '4px',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0.87)',
                        },
                      }}
                    >
                      {AGENCY_TYPE.map((type) => (
                        <MenuItem key={type.value} value={type}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {serverErrors.agencyType && (
                      <p className="text-danger mt-n2">{serverErrors.agencyType}</p>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} className="mt-">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="currency-label">Billing Currency</InputLabel>
                    <Select
                      labelId="currency-label"
                      id="currency-select"
                      value={values.agencyCurrency || AGENCY_CURRENCY[0]}
                      label="Billing Currency"
                      onChange={(event) => setFieldValue('agencyCurrency', event.target.value)}
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '4px',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0.87)',
                        },
                      }}
                    >
                      {AGENCY_CURRENCY.map((currency) => (
                        <MenuItem key={currency.value} value={currency}>
                          {currency.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {serverErrors.agencyCurrency && (
                      <p className="text-danger mt-n2">{serverErrors.agencyCurrency}</p>
                    )}
                  </FormControl>
                </Grid>
                {/* </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    helperText={touched.contactName && errors.contactName}
                    error={Boolean(touched.contactName && errors.contactName)}
                    label="Contact Name"
                    name="contactName"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('contactName', event.target.value)}
                    value={values.contactName}
                    variant="outlined"
                  />
                  {serverErrors.contactName && (
                    <p className="text-danger mt-n2">{serverErrors.contactName}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    helperText={touched.contactNo && errors.contactNo}
                    error={Boolean(touched.contactNo && errors.contactNo)}
                    label="Contact Number"
                    name="contactNo"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('contactNo', event.target.value)}
                    value={values.contactNo}
                    variant="outlined"
                  />
                  {serverErrors.contactNo && (
                    <p className="text-danger mt-n2">{serverErrors.contactNo}</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    helperText={touched.contactEmail && errors.contactEmail}
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                    label="Business E-mail"
                    name="contactEmail"
                    type="email"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('contactEmail', event.target.value)}
                    value={values.contactEmail}
                    variant="outlined"
                  />
                  {serverErrors.contactEmail && (
                    <p className="text-danger mt-n2">{serverErrors.contactEmail}</p>
                  )}
                </Grid>

                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    helperText={touched.gstNumber && errors.gstNumber}
                    error={Boolean(touched.gstNumber && errors.gstNumber)}
                    label="GST"
                    name="gstNumber"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('gstNumber', event.target.value)}
                    value={values.gstNumber}
                    variant="outlined"
                  />
                </Grid> */}

                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    helperText={touched.country && errors.country}
                    error={Boolean(touched.country && errors.country)}
                    label="Country"
                    name="country"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('country', event.target.value)}
                    value={values.country}
                    variant="outlined"
                  />
                  {serverErrors.country && <p className="text-danger mt-n2">{serverErrors.country}</p>}
                </Grid> */}
              </Grid>

              {/* <Button variant="contained" className="btn btn-secondary" type="button" onClick={handleCancel}>
                  Cancel
                </Button> */}

              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 4 }}
              >
                <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                  {/* Left side - Already have an account link */}
                  <Typography
                    className="h6 cursor-pointer"
                    variant="body2"
                    onClick={() => history.push('/login')}
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      textAlign: { xs: 'center', sm: 'left' },
                    }}
                  >
                    Already have an account? <span className="primary-color">Sign In</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                  {/* Right side - Create button */}
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    type="submit"
                    disabled={isLoadingCreateAgency || !values.agencyName}
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      width: '100%',
                      maxWidth: '200px', // Optional: to limit the max width on mobile devices
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <div className="justify-content-center">
            <Typography variant="h6">
              Your <span style={{ color: 'var(--primary)' }}>adzylytics</span> account has been
              created successfully.
            </Typography>
            <img src={checkIcon} style={{ width: '50px' }} alt="" className="mt-3" />
            <Typography className="mt-3">
              Please check your email for your login details.
            </Typography>
            <Button
              variant="contained"
              className="btn btn-primary mt-3"
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          </div>
        )}
      </div>
      {isLoadingCreateAgency && <OverlapLoader />}
    </div>
  );
};
