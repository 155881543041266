import React, { useMemo } from 'react';
import { Chart } from '../../components';
import getSessionData from '../../service-utils/session-util';
import { orderBy } from 'lodash';
import Services from '../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { CHART_COLOR, CHART_COLORS_LIST, getCurrencySymbol } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';

const CampaignsByCategoryChartByAdv = ({ chartType }) => {
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();

  const { data } = useQuery(
    ['CAMPAIGN_BY_CATEGORY', agencyId, advertiserId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getCampaignByCategoryByAdv(
        advertiserId,
        queryString.stringify(payload),
      );
      // console.log(response,"response")
      return response.data?.campaignCategorySummaryList?.map((item) => {
        return {
          ...item,
          spend: item.totalSpend.amount,
          childList: item.childList.map((item2) => ({ ...item2, spend: item2.totalSpend.amount })),
        };
      });
    },
    {
      enabled: !!agencyId,
      //staleTime: STALE_TIME.HALF_HOUR,
      //cacheTime: CACHE_TIME.HALF_HOUR,
    },
  );

  const currency = data ? data.map((item) => item.totalSpend.currency) : [];
  const currencyData = getCurrencySymbol(currency[0]);

  const chartData = useMemo(() => {
    let chartFactor;
    if (chartType === 'Impressions') {
      chartFactor = 'totalImpressionDelivered';
    } else if (chartType === 'Click') {
      chartFactor = 'totalClick';
    } else if (chartType === 'Spend') {
      chartFactor = 'spend';
    }
    // console.log('chartFactor', chartFactor);

    const sortedData =
      orderBy(
        data?.map((item) => ({
          ...item,
          label: item.categoryName,
          value: item[chartFactor],
          childList: orderBy(
            item.childList?.map((item) => ({
              ...item,
              label: item.campaignName,
              value: item[chartFactor],
            })),
            [(item) => item[chartFactor]],
            ['desc'],
          ),
        })),
        [(item) => item[chartFactor]],
        ['desc'],
      ) || [];

    const categoryData = [];
    const campaignData = [];
    const labels = [];
    // console.log('sortedData', sortedData);
    sortedData.forEach((item) => {
      categoryData.push(item[chartFactor]);
      labels.push(item.label || '');
      // item.childList.forEach((innerItem) => {
      // campaignData.push(innerItem[chartFactor]);
      // labels.push(innerItem.label || '');
      // });
    });
    // console.log('campaignData', campaignData);
    return {
      datasets: [
        // {
        //   data: campaignData,
        //   label: 'Campaign',
        //   backgroundColor: CHART_COLORS_LIST,
        // },
        {
          data: categoryData,
          label: 'Category',
          backgroundColor: CHART_COLORS_LIST,
        },
      ],
      labels,
    };
  }, [data, chartType]);
  const options = {
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.parsed;

            if (chartType === 'Spend') {
              // Add dollar symbol to the value
              // console.log(value);
              return label + ` : ${currencyData} ` + value.toFixed(2);
            }

            return label + ' : ' + value;
          },
        },
      },
    },
  };

  return (
    <div className="pie-chart-container1">
      <Chart type="Doughnut" chartData={chartData} options={options} />
    </div>
  );
};

export default CampaignsByCategoryChartByAdv;
