import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import { Table } from '../../components';
import { Button, Loader } from '../../common';
import Services from '../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { getSessionData } from '../../service-utils';
import { NewSelect } from '../../common';
import { useAdvertiser } from '../../context/AdvertiserProvider';

const LeadReport = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { agencyId } = getSessionData();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const { advertiserId } = useAdvertiser();
  const [leadFormOptions, setLeadFormOptions] = useState(null);
  const [selectedLeadForm, setSelectedLeadForm] = useState(null);
  const [leadOptions, setLeadOptions] = useState([]);
  const [lineItemOptions, setLineItemOptions] = useState([]);
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  let leadFormId = selectedLeadForm;
  let campaignId = selectedCampaign?.value;
  let lineItemId = selectedLineItem?.value;
  const {
    data,
    isLoading,
    refetch: refreshPage,
  } = useQuery(
    ['LEADREPORT_DATA', agencyId, leadFormId, campaignId, lineItemId, pageNumber, pageLimit],
    async () => {
      if (agencyId && leadFormId) {
        const payload = {
          agencyId,
          campaignId,
          lineItemId,
          pageNum: pageNumber - 1,
          pageSize: pageLimit,
        };
        const response = await Services.getLeadReportData(
          leadFormId,
          queryString.stringify(payload),
        );
        setTotalElements(response.data?.totalElements);
        return response.data?.leadDataDTOList || [];
      } else {
        // Return an empty array if conditions are not met
        return [];
      }
    },
    {
      enabled: !!agencyId, // Enable the query based only on agencyId
    },
  );

  const { data: leadFormData = [], isLoading2 } = useQuery(
    ['MASTERLIST_DATA', agencyId, advertiserId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterLeadFormData(
        advertiserId,
        queryString.stringify(payload),
      );
      return response.data?.masterList || [];
    },
    {
      enabled: !!agencyId && !!advertiserId,
    },
  );

  const {
    data: getCampaignLineItemList = [],
    isLoadingAllData,
    refetch,
  } = useQuery(
    ['HIERARCHY_DATA', agencyId, advertiserId, leadFormId],
    async () => {
      if (leadFormId) {
        const payload = { agencyId };

        const response = await Services.getCampaignLineItems(
          advertiserId,
          leadFormId,
          queryString.stringify(payload),
        );
        return response.data?.masterList || [];
      }
    },

    {
      enabled: !!agencyId,
    },
  );
  useEffect(() => {
    if (advertiserId) {
      setSelectedLineItem(null);
      setSelectedCampaign(null);
      if (leadOptions.length > 0) {
        setLeadFormOptions(leadOptions[0]);
        setSelectedLeadForm(leadOptions[0]?.value);
      } else {
        setLeadFormOptions([]);
        setCampaignOptions([]);
        setSelectedCampaign(null);
        setSelectedLeadForm(null);
        setSelectedLineItem(null);
        setLineItemOptions([]);
      }

      setPageNumber(1);
      refetch();
      refreshPage();
    }
  }, [advertiserId, leadOptions, refetch, refreshPage]);

  const getLineItemsForCampaign = (selectedCampaignId) => {
    const campaign = getCampaignLineItemList.find((c) => c.id === selectedCampaignId);
    return campaign ? campaign.children || [] : [];
  };

  // const lineItemsForSelectedCampaign = getLineItemsForCampaign(selectedCampaign?.value);

  useEffect(() => {
    const campaignsData = getCampaignLineItemList.map((campaign) => ({
      label: campaign.label,
      value: campaign.id,
    }));

    setCampaignOptions(campaignsData);
  }, [getCampaignLineItemList]);

  const handleCampaignChange = async (selectedCampaign) => {
    setSelectedCampaign(selectedCampaign);
    setSelectedLineItem(null); // Reset line item value

    let updatedLineItemOptions = [];

    if (selectedCampaign) {
      const lineItemsForSelectedCampaign = getLineItemsForCampaign(selectedCampaign.value);

      updatedLineItemOptions = lineItemsForSelectedCampaign.map((children) => ({
        label: children.label,
        value: children.id,
      }));
    }

    setLineItemOptions(updatedLineItemOptions);
    setPageNumber(1);
  };

  // Add this useEffect to reset line item options when the lead form changes
  useEffect(() => {
    setLineItemOptions([]);
  }, [selectedLeadForm]);
  const resetSelections = () => {
    setSelectedCampaign(null);
    setLineItemOptions([]);
    setSelectedLineItem(null);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Lead Report
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  useEffect(() => {
    const leadOptionsData =
      leadFormData?.map((leadForm) => ({
        label: leadForm.label,
        value: leadForm.id,
      })) || [];

    setLeadOptions(leadOptionsData);

    if (leadOptionsData.length > 0) {
      setSelectedLeadForm(leadOptionsData[0]?.value);
    }
  }, [leadFormData]);

  const handleLeadFormChange = (leadForm) => {
    setSelectedCampaign(null);
    setSelectedLineItem(null);

    setLeadFormOptions(leadForm);
    const selectedLeadFormId = leadForm?.value;
    setSelectedLeadForm(selectedLeadFormId);
    setPageNumber(1);
  };

  const formTables = {};
  if (data && data.length > 0) {
    data.forEach((item) => {
      const formId = item.leadFormId;

      if (!formTables[formId]) {
        formTables[formId] = [];
      }

      formTables[formId].push(item);
    });
  }

  const formTablesJSX = Object.entries(formTables).map(([formId, formTableData]) => {
    const uniqueFormDataKeys = Array.from(
      new Set(
        formTableData
          .filter((item) => item.leadFormId !== null)
          .flatMap((item) => Object.keys(item?.formData || {})),
      ),
    );

    const getTableHeader = [
      // { dataField: 'leadFormId', text: 'Form Id' },
      ...uniqueFormDataKeys.map((key) => ({
        dataField: `formData.${key}`,
        text: key.charAt(0).toUpperCase() + key.slice(1),
      })),
    ];

    return (
      <div key={formId} className="mb-4">
        <Table
          keyField="id"
          tableData={formTableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'name',
              order: 'asc',
            },
          ]}
        />
      </div>
    );
  });

  return (
    <>
      <div className="d-flex">
        <div className="mb-3 d-flex filters">
          <NewSelect
            required
            info="Select the lead form for which you want to see the lead report"
            options={leadOptions}
            value={leadFormOptions || leadOptions[0]}
            onChange={handleLeadFormChange}
            placeholder={`Lead Form`}
            isMulti={false}
            className="mt-n3 ml-3"
            disabled={isLoading2}
          />
        </div>
        <div className="mb-3 d-flex filters">
          <NewSelect
            required
            options={campaignOptions || []}
            value={selectedCampaign}
            onChange={handleCampaignChange}
            placeholder={`Campaign`}
            isMulti={false}
            className="mt-n3 ml-3"
            disabled={isLoadingAllData}
          />
        </div>
        <div className="mb-3 d-flex filters">
          <NewSelect
            required
            options={lineItemOptions || []}
            value={selectedLineItem}
            onChange={(obj) => {
              setSelectedLineItem(obj);
              setPageNumber(1);
            }}
            placeholder={`Line Items`}
            isMulti={false}
            className="mt-n3 ml-3"
            disabled={isLoadingAllData}
          />
        </div>
        <div>
          {selectedCampaign && (
            <Button className="btn btn-primary ml-3" variant="contained" onClick={resetSelections}>
              Reset
            </Button>
          )}
        </div>
      </div>

      {isLoading ? <Loader /> : data && data.length > 0 ? formTablesJSX : <h4>No data found.</h4>}
    </>
  );
};

export default LeadReport;
