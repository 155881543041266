import React, { useEffect, useMemo, useCallback } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { isEmpty } from 'lodash';
import { Typography } from '../../common';
import classnames from 'classnames';
import LandingFormData from './landing-form-data';

import { DyanamicForm } from './dyanamicForm';
import ThankYouPage from './thankyou-page';

const StepperComponent = ({ steps = [], activeStep = 0, setActiveStep }) => {
  return (
    <div className="stepper-component">
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={step.title}
              {...stepProps}
              onClick={() => setActiveStep(index)}
              className={classnames('cursor-pointer', {
                disabled: !step.isEnabled,
              })}
            >
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>{steps[activeStep].component}</div>
    </div>
  );
};

export const CreateLeadForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { agencyId = '', advertiserId } = getSessionData();
  const queryParams = queryString.parse(location?.search);
  const { formId: formIdCreate } = queryParams;
  const [activeStep, setActiveStep] = React.useState(0);
  const [isMetaPresent, setIsMetaPresent] = React.useState(false);
  const { formId } = useParams();

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: `/advertiser/lead-form`,
        },
      }),
    );
  }, [dispatch]);

  const { data: leadFormData = {}, refetch: refresh } = useQuery(
    ['LEADFORM_DATA', agencyId, advertiserId, formId],
    async () => {
      const payload = { agencyId };
      const id = formId || formIdCreate;
      const response = await Services.getLeadFormById(
        advertiserId,
        id,
        queryString.stringify(payload),
      );
      return response.data?.leadFormDTO;
    },
    { enabled: (!!formId || !!formIdCreate) && !!agencyId },
  );

  const payload = useMemo(
    () => ({
      // advertiserId,
      formId,
      // agencyId,
    }),
    [advertiserId, formId, agencyId],
  );

  const FormDataComplete = (newformId) => {
    setIsMetaPresent(true);
    const payload = {
      // advertiserId,
      formId: newformId,
    };
    if (!formId) {
      history.push({
        pathname: `/advertiser/lead-form-create`,
        hash: '1',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/lead-form/${formId}`,
        hash: '1',
      });
    }
  };

  const buildFormComplete = (newformId) => {
    setIsMetaPresent(true);
    const payload = {
      // advertiserId,
      formId: newformId,
    };
    if (!formId) {
      history.push({
        pathname: `/advertiser/lead-form-create`,
        hash: '2',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/lead-form/${formId}`,
        hash: '2',
      });
    }
  };
  const ThankYouPageComplete = () => {
    history.push({
      pathname: `/advertiser/lead-form`,
      hash: '2',
    });
  };
  useEffect(() => {
    const steps = getSteps(true);
    if (!isEmpty(steps) && !!location.hash) {
      handleStepClick(parseInt(location.hash?.split('#')[1]), steps);
    }
  }, [location.hash]);

  useEffect(() => {
    // Handle formId changes here, fetch data or trigger actions if needed
    const id = formId || formIdCreate;
    if (id) {
      // Fetch data or trigger actions
    }
  }, [formId, formIdCreate]);

  const getSteps = useCallback(
    (isMetaSubmit = false) => [
      {
        id: 0,
        title: 'Lead Form Data',
        isEnabled: true,
        component: (
          <LandingFormData
            leadFormData={leadFormData}
            payload={payload}
            refreshPage={refresh}
            goToNextStep={FormDataComplete}
          />
        ),
      },
      {
        id: 1,
        title: 'Build Form',
        isEnabled: true,
        component: (
          <DyanamicForm
            leadFormData={leadFormData}
            payload={payload}
            refreshPage={refresh}
            goToNextStep={buildFormComplete}
          />
        ),
      },
      {
        id: 2,
        title: 'Success Page',
        isEnabled: true,
        component: (
          <ThankYouPage
            leadFormData={leadFormData}
            payload={payload}
            refreshPage={refresh}
            goToNextStep={ThankYouPageComplete}
          />
        ),
      },
    ],
    [leadFormData, isMetaPresent],
  );

  const handleStepClick = useCallback(
    (index, steps = getSteps()) => {
      const selectedStep = steps.find((item) => item.id === index);
      if (selectedStep?.isEnabled) {
        setActiveStep(index);
        const newHash = `#${index}`;
        const newURL = `${location.pathname}${location.search}${newHash}`;
        history.push(newURL);
      } else {
        enqueueSnackbar('Please add lead form data first.', { variant: 'error' });
      }
    },
    [getSteps, history, location.pathname, location.search],
  );

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 form-container-height">
      <div className="d-flex justify-content-center my-3">
        <Typography color="textPrimary" variant="h5">
          {!!formId ? 'Edit Lead Form' : 'Create New Lead Form'}
        </Typography>
      </div>
      <StepperComponent
        steps={getSteps()}
        activeStep={activeStep}
        setActiveStep={(index) => handleStepClick(index)}
      />
    </div>
  );
};
