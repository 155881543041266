import React, { useMemo, useEffect } from 'react';
import { Chart } from '../../../components';
import Services from '../../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { isEmpty, orderBy } from 'lodash';
import { CHART_COLOR, getCurrencySymbol } from '../../../common/utils';
import { externalTooltipHandler } from '../../../components/chart/index';
import { getclickCountsData, getimpressionsData, getspendsData } from '../../../common/utils';

export const ImpressionDeviceType = ({ agencyId, selectedCampaign, payload }) => {
  const selectedCampaignId = selectedCampaign?.id;
  let startDate = payload?.startDate; // Check if payload exists before accessing properties
  let endDate = payload?.endDate;
  let lineItemIds = payload?.lineItemIds;
  let creativeIds = payload?.creativeIds;
  let detectedDeviceTypes = payload?.detectedDeviceTypes;
  let detectedDeviceOs = payload?.detectedDeviceOs;
  let states = payload?.states;
  let countries = payload?.countries;
  const { data: chartData = [], refetch } = useQuery(
    [
      'DEVICETYPE_WISE_IMPRESSIONS',
      selectedCampaignId,
      startDate,
      endDate,
      detectedDeviceOs,
      agencyId,
      lineItemIds,
      creativeIds,
      detectedDeviceTypes,
      states,
      countries,
    ],
    async () => {
      const queryStringParams = {
        startDate,
        endDate,
        agencyId,
        ...(lineItemIds && { lineItemIds }),
        ...(creativeIds && { creativeIds }),
        ...(detectedDeviceTypes && { detectedDeviceTypes }),
        ...(detectedDeviceOs && { detectedDeviceOs }),
        ...(states && { states }),
        ...(countries && { countries }),
      };
      const response = await Services.getCampaignDeviceType(
        selectedCampaignId,
        queryString.stringify(queryStringParams),
      );

      return response.data?.graphDataList;
    },
    {
      enabled: !!agencyId && !isEmpty(selectedCampaignId) && !!startDate && !!endDate,
    },
  );
  useEffect(() => {
    if (startDate && endDate) {
      // Both startDate and endDate have values, trigger API call
      refetch();
    }
  }, [
    startDate,
    lineItemIds,
    detectedDeviceOs,
    detectedDeviceTypes,
    states,
    countries,
    creativeIds,
    endDate,
    refetch,
  ]);

  const data = useMemo(() => {
    const sortedData =
      orderBy(
        chartData?.map((item) => ({
          ...item,
          y: item.value,
        })),
        ['value', 'label'],
        ['desc', 'asc'],
      ) || [];

    const impressions = sortedData.map((item) => item.value);
    let impressionData = getimpressionsData(impressions);

    const spends = sortedData.map((item) => item.totalCost.amount);
    let spendData = getspendsData(spends);

    const clickCounts = sortedData.map((item) => item.clickCount);
    let clickData = getclickCountsData(clickCounts);

    return {
      labels: sortedData?.map((item) => item.label),
      datasets: [
        {
          fill: false,
          label: 'Spend',
          data: spendData,
          actualData: spends,
          backgroundColor: CHART_COLOR.SPEND,
          borderColor: CHART_COLOR.SPEND,
          pointRadius: 0,
          borderWidth: 2,
          type: 'line',
        },
        {
          fill: true,
          label: 'Impressions',
          data: impressionData,
          actualData: impressions,
          backgroundColor: CHART_COLOR.IMPRESSION,
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          fill: true,
          label: 'Click Count',
          data: clickData,
          actualData: clickCounts,
          backgroundColor: CHART_COLOR.CLICK_COUNT,
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
  }, [chartData]);
  const currency = chartData.map((item) => item.totalCost.currency);
  let currencyData = getCurrencySymbol(currency[0]);

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem = {}, data) => {
          const {
            dataset: { label = '', actualData = [] },
            dataIndex,
          } = tooltipItem || {};

          let tooltipText = `${label} : ${actualData[dataIndex]}`;

          if (label === 'Spend') {
            tooltipText = `${label} : ${currencyData} ${actualData[dataIndex]}`;
          }

          return tooltipText;
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentile',
        },
      },
    },
  };
  return <Chart type="Bar" chartData={data} options={options} />;
};
