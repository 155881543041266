import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Services from '../../service-utils/services';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { AGENCY_TYPE, AGENCY_CURRENCY } from '../../libs/constants';
import '../../views/register.scss';
import { Link } from 'react-router-dom';
import history from '../../history';
import { handleMsgOnForm } from '../../common/utils';
export const AgencyRegistration = ({ data, registerCode }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [errorText, setErrorText] = useState();
  const { mutate: createAgency, isLoading: isLoadingCreateAgency } = useMutation(
    async (values) => {
      const {
        agencyName,
        agencyType,
        contactNo,
        contactEmail,
        contactName,
        password,
        agencyCurrency,
      } = values;
      const payload = {
        agencyDTO: {
          agencyName,
          agencyType: agencyType?.value,
          contactNo,
          contactName,
          currency: agencyCurrency?.value || 'USD',
          contactEmail,
        },
        userDTO: {
          id: data?.id,
          agencyId: data?.agencyId,
          name: contactName,
          password,
        },
      };
      const response = await Services.agencyDataAdded(registerCode, payload);
      return response.data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error In Registration.';
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setErrorText(errorMsg);
      },
      onSuccess: () => {
        enqueueSnackbar('Registration successfully.', { variant: 'success' });
        history.push('/login');
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      agencyName: '',
      agencyType: '',
      contactName: '',
      contactNo: '',
      contactEmail: '',
      password: '',
      rePassword: '',
      agencyCurrency: AGENCY_CURRENCY[0],
    },
    validationSchema: Yup.object({
      agencyName: Yup.string()
        .min(2, 'Must be more than 1 character')
        .max(255, 'Must be less than 255 characters')
        .required('Company name is required.'),
      password: Yup.string().required('Password is required.'),
      rePassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match.')
        .required('Confirm Password is required.'),
    }),
    onSubmit: () => createAgency(values),
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      setFieldValue('contactEmail', data?.email); // Correct field name for email
    }
  }, [data, setFieldValue]);

  return (
    <div className="registration-page mt-2 mt-md-5">
      {/* <Typography variant="h6" align="center" gutterBottom>
          Create your account
        </Typography> */}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Company Name"
              name="agencyName"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('agencyName', event.target.value)}
              value={values.agencyName}
              error={Boolean(touched.agencyName && errors.agencyName)}
              helperText={touched.agencyName && errors.agencyName}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} className="mt-">
            <FormControl fullWidth variant="outlined">
              <InputLabel id="company-type-label">Company Type</InputLabel>
              <Select
                labelId="company-type-label"
                id="company-type-select"
                value={values.agencyType}
                label="Company Type"
                onChange={(event) => setFieldValue('agencyType', event.target.value)}
                required
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '4px',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.87)',
                  },
                }}
              >
                {AGENCY_TYPE.map((type) => (
                  <MenuItem key={type.value} value={type}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {serverErrors.agencyType && (
                <p className="text-danger mt-n2">{serverErrors.agencyType}</p>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} className="mt-">
            <FormControl fullWidth variant="outlined">
              <InputLabel id="currency-label">Billing Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency-select"
                value={values.agencyCurrency || AGENCY_CURRENCY[0]}
                label="Billing Currency"
                onChange={(event) => setFieldValue('agencyCurrency', event.target.value)}
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '4px',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.87)',
                  },
                }}
              >
                {AGENCY_CURRENCY.map((currency) => (
                  <MenuItem key={currency.value} value={currency}>
                    {currency.label}
                  </MenuItem>
                ))}
              </Select>
              {serverErrors.agencyCurrency && (
                <p className="text-danger mt-n2">{serverErrors.agencyCurrency}</p>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Contact Name"
              name="contactName"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('contactName', event.target.value)}
              value={values.contactName}
              error={Boolean(touched.contactName && errors.contactName)}
              helperText={touched.contactName && errors.contactName}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Contact Number"
              name="contactNo"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('contactNo', event.target.value)}
              value={values.contactNo}
              error={Boolean(touched.contactNo && errors.contactNo)}
              helperText={touched.contactNo && errors.contactNo}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Business E-mail"
              name="contactEmail"
              type="email"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('contactEmail', event.target.value)}
              value={values.contactEmail}
              variant="outlined"
              InputProps={{
                readOnly: true, // Makes the field read-only
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('password', event.target.value)}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              variant="outlined"
            />
            {serverErrors.password && <p className="text-danger mt-n1">{serverErrors.password}</p>}
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              label="Confirm Password"
              name="rePassword"
              type="password"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('rePassword', event.target.value)}
              value={values.rePassword}
              error={Boolean(touched.rePassword && errors.rePassword)}
              helperText={touched.rePassword && errors.rePassword}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  name="termsAccepted"
                  checked={values.termsAccepted}
                  onChange={(event) => setFieldValue('termsAccepted', event.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I agree to the{' '}
                  <Link
                    className="text-primary"
                    to={{ pathname: 'https://www.adzylytics.com/terms-and-conditions/' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </Link>
                </Typography>
              }
            />
            {touched.termsAccepted && errors.termsAccepted && (
              <Typography className="text-danger">{errors.termsAccepted}</Typography>
            )}
          </Grid>
          {!!errorText && <Chip label={errorText} className="alert-danger w-100" />}

          <Grid item xs={12}>
            <Button
              variant="contained"
              className="btn btn-primary"
              type="submit"
              disabled={isLoadingCreateAgency}
              fullWidth
            >
              {isLoadingCreateAgency ? 'Submiting...' : 'Submit'}{' '}
            </Button>
          </Grid>
        </Grid>
      </form>

      {isLoadingCreateAgency && <OverlapLoader />}
    </div>
  );
};
