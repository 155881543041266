import { createContext, useContext, useState } from 'react';
import { CookieUtil } from '../service-utils';

const AdvertiserContext = createContext();

export const AdvertiserProvider = ({ children }) => {
  // Define the constant
  const ADVERTISER_ID_COOKIE_NAME = 'advertiserId';

  // Move the function declaration to the top
  const getDefaultAdvertiserId = () => {
    const { getCookies } = CookieUtil();
    const cookies = getCookies();
    const defaultId = cookies[ADVERTISER_ID_COOKIE_NAME] || 'defaultAdId';
    return parseInt(defaultId);
  };

  // Initialize the state with the default value
  const [advertiserId, setAdvertiserId] = useState(getDefaultAdvertiserId());

  // Move the function declaration to the top
  const updateAdvertiserId = (newId) => {
    const { setAppCookie } = CookieUtil();
    setAppCookie(ADVERTISER_ID_COOKIE_NAME, newId);
    setAdvertiserId(newId);
  };

  return (
    <AdvertiserContext.Provider value={{ advertiserId, updateAdvertiserId }}>
      {children}
    </AdvertiserContext.Provider>
  );
};

export const useAdvertiser = () => {
  const context = useContext(AdvertiserContext);
  if (!context) {
    throw new Error('useAdvertiser must be used within an AdvertiserProvider');
  }
  return context;
};
