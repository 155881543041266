import React from 'react';

const NetworkDetector = (ComposedComponent) => {
  const NetworkDetectorComp = (props) => {
    return (
      <div>
        <ComposedComponent {...props} />
      </div>
    );
  };

  return NetworkDetectorComp;
};

export default NetworkDetector;
