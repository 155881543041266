import React, { cloneElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const DisableOverlayTrigger = ({ overlay = renderTooltip, children, ...rest }) => {
  return (
    <OverlayTrigger
      placement={rest?.placement ? rest.placement : 'left'}
      delay={{ show: 250, hide: 400 }}
      overlay={overlay}
    >
      {cloneElement(children, { ...rest })}
    </OverlayTrigger>
  );
};

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    <>You do not have permission</>
  </Tooltip>
);
