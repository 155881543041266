import React, { Suspense, lazy } from 'react';
import desktop from './../../../assets/img/desktop.png';
import ukuleleTabs from './../../../assets/img/ukuleleTabs.png';
const LazyImage = lazy(() => import('./LazyImage'));

const BANNER_120_240 = ({ imageUrl = '' }) => {
  return (
    <>
      {desktop && (
        <div className="BANNER_120_240 desktop_app_wrapper">
          <img src={desktop} alt="desktop" className="frame_image w-100" />
          <img src={ukuleleTabs} alt="banner" className="app_image" />

          <Suspense>
            <LazyImage imageUrl={imageUrl} />
            <LazyImage imageUrl={imageUrl} className="case2" />
          </Suspense>
        </div>
      )}
    </>
  );
};

export default BANNER_120_240;
