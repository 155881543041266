import React, { Suspense, lazy } from 'react';
import mobile_frame from './../../../assets/img/mobile_frame.png';
import vita from './../../../assets/img/vita.jpg';
import terabox_320_50 from './../../../assets/img/terabox_320_50.jpg';
const LazyImage = lazy(() => import('./LazyImage'));
const BANNER_320_50 = ({ imageUrl = '' }) => {
  return (
    <>
      {mobile_frame && (
        <div className="BANNER_320_50 mobile_app_wrapper">
          <img src={vita} alt="banner" className="app_image" />
          <img src={mobile_frame} alt="tablet" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} />
          </Suspense>
        </div>
      )}
    </>
  );
};

export default BANNER_320_50;
