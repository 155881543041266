import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line, Pie, Bar, Doughnut } from 'react-chartjs-2';
import { color } from 'highcharts';

import { CHART_COLOR, getCurrencySymbol } from '../../common/utils';
ChartJS.register(...registerables);

const CHART_TYPES = {
  LINE: 'Line',
  PIE: 'Pie',
  DOUGHNUT: 'Doughnut', // Add the 'DOUGHNUT' type
  BAR: 'Bar',
  HORIZONTAL_BAR: 'HorizontalBar',
  VERTICAL_BAR: 'VerticalBar',
};

const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const defaultOptionsForBarChart = {
  scales: {
    x: {
      display: true,
      ticks: {
        autoSkip: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom', // Set the legend position to 'bottom' for all charts
    },
  },
};

const defaultOptionsForHorizontalBarChart = {
  indexAxis: 'x',
  options: {
    scales: { y: { stacked: true, min: 0 } },
    plugins: {
      zoom: {
        limits: {
          y: { min: 0, max: 200, minRange: 50 },
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false,
          },
          mode: 'xy',
        },
      },
      title: {
        display: true,
        position: 'bottom',
      },
    },
    onClick(e) {
      const chart = e.chart;
      chart.options.plugins.zoom.zoom.wheel.enabled =
        !chart.options.plugins.zoom.zoom.wheel.enabled;
      chart.options.plugins.zoom.zoom.pinch.enabled =
        !chart.options.plugins.zoom.zoom.pinch.enabled;
      chart.update();
    },
  },
};
const defaultOptionsForVerticalBarChart = {
  indexAxis: 'x',
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      display: true,
      ticks: {
        autoSkip: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom', // Set the legend position to 'bottom' for all charts
    },
  },
};

const PieChart = ({ chartData, options = {} }) => {
  return <Pie data={chartData} options={{ ...defaultOptions, ...options }} />;
};

const DoughnutChart = ({ chartData, options = {} }) => {
  // console.log(chartData);
  return <Doughnut data={chartData} options={{ ...defaultOptions, ...options }} />;
};

const BarChart = ({ chartData, options = {}, height, width }) => {
  return (
    <Bar
      data={chartData}
      options={{ ...defaultOptionsForBarChart, ...options }}
      height={height}
      width={width}
    />
  );
};

const LineChart = ({ chartData, options = {}, height, width }) => {
  const numSlots = 7;

  const modifiedOptions = {
    ...options,
    animation: {
      duration: 0, // Disable animations by setting duration to 0
    },
    scales: {
      x: {
        ...options.scales?.x,
        ticks: {
          ...options.scales?.x?.ticks,
          maxTicksLimit: numSlots,
          callback:
            options.scales?.x?.ticks?.callback ||
            ((value, index) => {
              const minIndex = Math.max(0, index);
              const maxIndex = Math.min(index, chartData.labels.length - 1);
              return chartData.labels[minIndex] || chartData.labels[maxIndex];
            }),
        },
      },
      ...options.scales?.yAxes?.reduce((acc, axis) => {
        acc[axis.id] = {
          ...axis,
          type: 'linear',
          display: true,
          beginAtZero: true,
          title: {
            display: true,
            text: axis.label,
            font: {
              size: 12,
              weight: 'bold',
            },
            color: axis.color,
            padding: 10,
          },
        };
        return acc;
      }, {}),
    },
    plugins: {
      ...options.plugins,
    },
  };
  return <Line options={modifiedOptions} data={chartData} height={height} width={width} />;
};

const Chart = ({ type = CHART_TYPES.LINE, chartData = {}, options = {}, height, width }) => {
  switch (type) {
    case CHART_TYPES.LINE:
      return <LineChart height={height} width={width} chartData={chartData} options={options} />;
    case CHART_TYPES.BAR:
      return <BarChart height={height} width={width} chartData={chartData} options={options} />;
    case CHART_TYPES.PIE:
      return <PieChart chartData={chartData} options={options} />;
    case CHART_TYPES.DOUGHNUT: // Add the 'DOUGHNUT' case
      return <DoughnutChart chartData={chartData} options={options} />;
    case CHART_TYPES.HORIZONTAL_BAR:
      return (
        <Bar data={chartData} options={{ ...defaultOptionsForHorizontalBarChart, ...options }} />
      );
    case CHART_TYPES.VERTICAL_BAR:
      return (
        <Bar data={chartData} options={{ ...defaultOptionsForVerticalBarChart, ...options }} />
      );
    default:
      return <PieChart chartData={chartData} options={options} />;
  }
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('.chart-custom-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('chart-custom-tooltip');

    const table = document.createElement('table');
    table.classList.add('m-0');

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');

      span.style.background = colors.backgroundColor || colors.borderColor;
      span.style.borderColor = colors.borderColor;
      span.classList.add('tooltip-marker');

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Clear the table content
    tableRoot.innerHTML = '';

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default Chart;
