import React, { useState } from 'react';
import { Table } from '../../../components';
import { Loader } from '../../../common';
import Services from '../../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { getSessionData } from '../../../service-utils';
const CampaignDetails = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  // const [disabledId, setDisabledId] = useState();
  // const [enableId, setEnableId] = useState();
  const { agencyId } = getSessionData();

  const { data, isLoading } = useQuery(
    ['FETCHLIVELINEITEMS_DATA', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.fetchLiveLineItems(queryString.stringify(payload));
      return response.data;
    },
    {
      enabled: !!agencyId,
    },
  );

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const getTableHeader = [
    {
      dataField: 'advertiser',
      text: 'Advertiser Name',
    },
    {
      dataField: 'campaignId',
      text: 'Campaign Id',
    },
    {
      dataField: 'campaign',
      text: 'Campaign Name',
    },
    {
      dataField: 'lineItem',
      text: 'LineItem Name',
    },
    { dataField: 'startDateTime', text: 'Start Date' },
    { dataField: 'endDateTime', text: 'End Date' },
    {
      dataField: 'impressionGoal',
      text: 'Impression Goal',
      formatter: (cell, row) => (cell != null ? parseFloat(cell).toFixed(0) : '-'),
    },
    {
      dataField: 'spendGoal',
      text: 'Spend Goal',
      formatter: (cell, row) =>
        cell != null ? (Number.isInteger(cell) ? cell.toFixed(0) : cell.toFixed(1)) : '-',
    },
    {
      dataField: 'impressionDelivered',
      text: 'Delivered Impressions',
      formatter: (cell, row) => (cell != null ? parseFloat(cell).toFixed(0) : '0'),
    },
    {
      dataField: 'deliveredSpend',
      text: 'Delivered Spend',
      formatter: (cell, row) =>
        cell != null ? (Number.isInteger(cell) ? cell.toFixed(0) : cell.toFixed(2)) : '0',
    },
  ];
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={data}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'name',
              order: 'asc',
            },
          ]}
        />
      )}
    </>
  );
};
export default CampaignDetails;
