import React,{useEffect} from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { RegistrationForm } from '../../components';

export const AgencyCreate = () =>{
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
        text: 'Back'
        },}),
    );
  }, [dispatch]);
    return (
      <>
        <RegistrationForm redirectFrom="agency"/>
      </>
    );
}